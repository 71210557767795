import React from "react";
import { QRCode } from "react-qr-svg";

export default class QRCodeComponent extends React.Component {
    
    render() {
        return (
            <QRCode
                bgColor="#FFFFFF"
                fgColor="#000000"
                level="Q"
                style={{ width: 256 }}
                value="some text"
            />
        );
    }
}