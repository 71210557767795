import { Divider, Grid, Link, Stack, styled, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import React from "react";

import fbIcon from "../media/fbicon.png";
import twitterIcon from "../media/twittericon.png";
import linkedinIcon from "../media/linkedinicon.png";
import Iconify from "./Iconify";
import logo from "../media/logo2.png";
import androidBadge from "../media/google-play-badge.png";
import iosBadge from "../media/ios.svg";

const Footer = () => {
  const CustomContainer = styled(Container)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-around",
    gap: theme.spacing(5),
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      textAlign: "center",
    },
  }));

  const IconBox = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    gap: "1rem",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  }));

  const FooterLink = styled("span")(({ theme }) => ({
    fontSize: "16px",
    color: "#7A7A7E",
    fontWeight: "300",
    cursor: "pointer",
    "&:hover": {
      color: "#000",
    },
  }));
  const NavbarLogo = styled("img")(({ theme }) => ({
    cursor: "pointer",
    width: 100,
  }));

  return (
    <>


      <Box sx={{ bgcolor: '#1F1F1F', color: 'white' }}>
        <Box width='100%' height={3} sx={{ background: "linear-gradient(0.25turn, #e66465, #9198e5)", }} />
        <Container sx={{ pt: 3 }}>
          <Grid container spacing={4}>
            {/* Section Logo et Description */}
            <Grid item xs={12} sm={6} md={3}>
              <Link href="/fr/" underline="none">
                <img src={logo} alt="logo" style={{ width: '120px', marginBottom: '16px' }} />
              </Link>
              <Typography variant="body2">
                La plateforme incontournable pour acheter et gérer vos tickets d'événements.
              </Typography>
            </Grid>

            {/* Section Liens Utiles */}
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="subtitle1" gutterBottom>
                Liens utiles
              </Typography>
              <Link href="#" color="inherit" underline="hover"><Typography variant="body2">Contact</Typography></Link>
              <Link href="https://support.choptonticket.com/fr/categories/legacy/articles/cgu" color="inherit" underline="hover"><Typography variant="body2">Conditions d'utilisation</Typography></Link>
              <Link href="https://support.choptonticket.com/fr/categories/legacy/articles/cgp" color="inherit" underline="hover"><Typography variant="body2">Politique de confidentialité</Typography></Link>
            </Grid>

            {/* Section Réseaux Sociaux */}
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="subtitle1" gutterBottom>
                Suivez-nous
              </Typography>
              <Box sx={{ display: 'flex', gap: 2 }}>
                <Link href="#" color="inherit">
                  <Iconify icon="akar-icons:facebook-fill" width={24} height={24} />
                </Link>
                <Link href="#" color="inherit">
                  <Iconify icon="akar-icons:instagram-fill" width={24} height={24} />
                </Link>
                <Link href="#" color="inherit">
                  <Iconify icon="akar-icons:twitter-fill" width={24} height={24} />
                </Link>
                <Link href="#" color="inherit">
                  <Iconify icon="akar-icons:linkedin-fill" width={24} height={24} />
                </Link>
              </Box>
            </Grid>

            {/* Section Achat Sécurisé */}
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="subtitle1" gutterBottom>
                Achat Sécurisé
              </Typography>
              <Typography variant="body2" gutterBottom>
                Profitez de notre plateforme sécurisée pour acheter vos tickets en toute confiance.
              </Typography>

            </Grid>
          </Grid>

          {/* Section Télécharger l'Application */}
          <Box sx={{ mt: 4, textAlign: 'center' }}>
            <Typography variant="subtitle1" gutterBottom>
              Téléchargez notre application
            </Typography>
            
            <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center', alignItems:'center' }}>
              <Link href="https://apps.apple.com" target="_blank" rel="noopener noreferrer">
              <img
                  src={androidBadge}
                  alt="Apple Store"
                  style={{ maxWidth: 130 }}
                />
              </Link>
              <Link href="https://play.google.com" target="_blank" rel="noopener noreferrer">
              <img style={{ Width: 150 }} src={iosBadge} alt="Google Play" />
              </Link>
            </Box>
          </Box>
        </Container>
        <Box sx={{}}>
          <Container>
            {/* Section Support */}
            <Box sx={{ mt: 4 }}>
              <Typography variant="subtitle1" gutterBottom>
                Support
              </Typography>
              <Typography variant="body2" >
                Besoin d'aide ? Consultez notre page de support ou contactez-nous.
              </Typography>
              <Link href="https://support.choptonticket.com/" target="_blank" // pour ouvrir le lien dans un nouvel onglet
                rel="noopener noreferrer" color="inherit" underline="hover"><Typography variant="body2">Page de support</Typography></Link>
              
              <Link
                href="http://api.whatsapp.com/send?phone=2250709219343"
                underline="hover"
                target="_blank" // pour ouvrir le lien dans un nouvel onglet
                rel="noopener noreferrer" color="inherit" // pour des raisons de sécurité
              >
                <Typography variant="body2">Contactez-nous</Typography>
              </Link>
              
            </Box>

            {/* Redirection vers l'application partenaire */}
            <Box sx={{ textAlign: 'center', mt: 4 }}>
              <Link target="_blank" // pour ouvrir le lien dans un nouvel onglet
                rel="noopener noreferrer" href="https://organisateurs.choptonticket.com" color="inherit" underline="hover">
                Organiser des evenements
              </Link>
            </Box>
          </Container>
          {/* Droits d'Auteur */}
          <Box sx={{ textAlign: 'center', mt: 4 }}>
            <Typography variant="body2" color="white">
              © {new Date().getFullYear()} Choptonticket. Tous droits réservés.
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Footer;