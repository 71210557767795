import {
  Card,
  Box,
  styled,
  Typography,
  CardMedia,
  Grid,
  Stack,
  Divider,
  Skeleton,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

import { format } from "date-fns";
import { fr } from "date-fns/locale";

const ResearchItemLoading = () => {
  return (
    <Grid container spacing={5} paddingY={2}>
      <Grid item xs={12}>
        <Stack direction="row" spacing={2}>
          <Stack paddingX="1rem" direction="column" width="70%" justifyContent="space-between">
            <Skeleton />
            <Skeleton width="30%" />
            <Skeleton width="80%" />
            <Skeleton width="60%" />
            <Skeleton width="30%" />
            <Box height="20%"></Box>
          </Stack>
          <Skeleton variant="rectangular" height={200} width="30%" />
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Stack direction="row" spacing={2}>
          <Stack direction="column" width="70%" justifyContent="space-between">
            <Skeleton />
            <Skeleton width="30%" />
            <Skeleton width="80%" />
            <Skeleton width="60%" />
            <Skeleton width="30%" />
            <Box height="20%"></Box>
          </Stack>
          <Skeleton variant="rectangular" height={200} width="30%" />
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Stack direction="row" spacing={2}>
          <Stack direction="column" width="70%" justifyContent="space-between">
            <Skeleton />
            <Skeleton width="30%" />
            <Skeleton width="80%" />
            <Skeleton width="60%" />
            <Skeleton width="30%" />
            <Box height="20%"></Box>
          </Stack>
          <Skeleton variant="rectangular" height={200} width="30%" />
        </Stack>
      </Grid>
    </Grid>
  );
};

export default ResearchItemLoading;
