import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
    Avatar,
    Button,
    Card,
    CardContent,
    CardMedia,
    Container,
    Divider,
    Grid,
    IconButton,
    Link,
    Menu,
    MenuItem,
    Modal,
    Stack,
    styled,
    TextField,
} from "@mui/material";
import androidBadge from "../media/google-play-badge.png";
import iosBadge from "../media/ios.svg";
import CircularProgress from "@mui/material/CircularProgress";
import CustomButton from "../Components/CustomButton";
import logoImg from "../media/logo2.png";
import Footer from "../Components/Footer";
import { UserAuth } from "../context/AuthContext";
import Iconify from "../Components/Iconify";
import { useNavigate, useParams } from "react-router-dom";
import Ticket from "../Components/profil/ticket";
import { collection, doc, getDoc, getDocs, query } from "firebase/firestore";
import { db, functions } from "../firebase.config";
import { httpsCallable } from "firebase/functions";

// ----------------------------------------------------------------------

const styleModal = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 400,
    bgcolor: "white",
    border: "1px solid white",
    borderRadius: "7px",
    boxShadow: 0,
    p: 3,
};

const NavbarLogo = styled("img")(({ theme }) => ({
    cursor: "pointer",
    width: 50,
}));


export default function ResetPass() {
     // Récupérer redirectUrl depuis les paramètres de l'URL
    const queryParams = new URLSearchParams(window.location.search);
    const redirectUrl = queryParams.get('redirectUrl') ? decodeURIComponent(queryParams.get('redirectUrl')) : '/fr';  // '/home' est une URL par défaut si aucun redirectUrl n'est défini
    const email = queryParams.get('email')
    const { resetPass,logIn } = UserAuth();
    const [password, setPassword] = React.useState('');
    const [errorMsg, setErrorMsg] = React.useState('');
    const [passError, setPassError] = React.useState({error:false, msg: ''});
    const [passConfirmError, setPasswordConfirmError] = React.useState({error:false, msg: ''});
    const [error, setError] = React.useState(false);
    const [passwordConfirm, setPasswordConfirm] = React.useState('');
    const navigate = useNavigate();
    const [oobCode, setOobCode] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const { upn } = useParams();

    React.useEffect(() => {
        (async () => {
          try {
            // setLoading(true);
            const tmp = upn.split('&');
            setOobCode(tmp[0]);
          } catch (error) {
            console.log(error);
          }
        })();
      }, []);

    const reset = async (e) => {
        setLoading(true);
        setError(false);
        console.log(redirectUrl)
      // Utiliser substring pour extraire l'URI
      const urlStartIndex = redirectUrl.indexOf('/', redirectUrl.indexOf('//') + 2); // Trouver le début de l'URI
      const uri = redirectUrl.substring(urlStartIndex); // Extraire l'URI
      console.log(uri);
      console.log(email)
      // navigate(uri);
        setPassError({error:false,msg:'Champs requis'})
        setPasswordConfirmError({error:false,msg:'Champs requis'})
        if (password === '' || passwordConfirm === '') {
            if (password === '') {
                setPassError({error:true,msg:'Champs requis'})
            }
            if (passwordConfirm === '') {
                setPasswordConfirmError({error:true,msg:'Champs requis'})
            }
            setLoading(false);
            return;
          }else if (password !== passwordConfirm) {
            setPassError({error:true,msg:'Veuillez entrer des valeurs identitques'})
            setPasswordConfirmError({error:true,msg:'Veuillez entrer des valeurs identitques'})
          
          setLoading(false);
          return;
        }
        try {
          await resetPass(oobCode, password);
          await logIn(email, password)
          // setPassReset(true);
          navigate(uri)
        } catch (err) {
          console.log(err.toString());
          setErrorMsg('Une erreur s\'est produite');
          setError(true);
          setLoading(false);
        }
        setLoading(false);
      };

    return (
        <Container sx={{ paddingX: 5, color: "white" }}>
            <Box
                sx={{
                    position: "fixed",
                    width: "100%",
                    paddingY: 8,
                    bottom: 0,
                    left: 0,
                    top: 0,
                    zIndex: -1,
                }}
            >
                <Box width='100%' height={3} sx={{ background: "linear-gradient(0.25turn, #e66465, #9198e5)", }} />
            </Box>
            <Container>
                <Box paddingTop={1}>
                    <Link href="/fr/" underline="none">
                        <NavbarLogo src={logoImg} alt="logo" />
                    </Link>
                </Box>
            </Container>

            <Grid container sx={{ display: "flex", justifyContent: "center", paddingY: 10, }}>
                <Grid item xs={12} sm={10} md={5}>
                    
                    <Box sx={{
                        padding: 5, borderRadius: '10px', border: '1px solid gainsboro', boxShadow: 'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
                    }}>

                        <Stack spacing={4} justifyContent='center'>
                        <Typography
                        fontSize={30}
                        textAlign={'start'}
                        sx={{
                            color: "#141414",
                            fontWeight: "800",
                        }}
                    >
                        Changer de mot de passe
                    </Typography>
                            <Stack spacing={2}>
                                <TextField
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                    fullWidth
                                    type="password"
                                    placeholder="Mot de passe"
                                    error={passError.error}
                                    helperText={ passError.error ? passError.msg : null}
                                >
                                    {" "}
                                </TextField>
                                <TextField
                                    value={passwordConfirm}
                                    onChange={(e) => setPasswordConfirm(e.target.value)}
                                    required
                                    fullWidth
                                    type="password"
                                    placeholder="Confirmation du mot de passe"
                                    error={passConfirmError.error}
                                    helperText={ passConfirmError.error ? passConfirmError.msg : null}
                                >
                                    
                                </TextField>
                               
                            </Stack>
                            {
                                error &&
                                <Box sx={{borderRadius:'10px', bgcolor:'#141414'}}>
                                <Typography variant="body2" sx={{color:"red"}}>{errorMsg}</Typography>
                            </Box>
                            }
                           
                            <CustomButton buttonText={'Modifier'} type='primaire' loading={loading} onClick={reset} />
                        </Stack>
                    </Box>
                </Grid>
            </Grid>

        </Container>
    );
}
