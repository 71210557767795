import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardMedia,
  Checkbox,
  CircularProgress,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Modal,
  Radio,
  RadioGroup,
  Skeleton,
  Stack,
  TextField,
} from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import CustomButton from "../Components/CustomButton";
import logoImg from "../media/suspect.jpeg";
import Iconify from "../Components/Iconify";
import {
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  increment,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../firebase.config";
import { useNavigate, useParams } from "react-router-dom";
import { borderRadius } from "@mui/system";
import { format } from "date-fns";
import { fr, id } from "date-fns/locale";
import Maps from "../Components/map/Maps";
import useResponsive from "../Components/hooks/useResponsive";
import ReserDesktop from "../Components/reservation/reserDesktop";
import ReserMobile from "../Components/reservation/resMobile";
import Footer from "../Components/Footer";
import Contact from "../Components/Contact";
import { Variables } from "../context/VariableContext";
import { UserAuth } from "../context/AuthContext";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
// import * as pay from "https://paydunya.com/assets/psr/js/psr.paydunya.min.js"

// accordion-------------------------------------------------
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `0px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <Iconify icon={"fluent:add-28-filled"} sx={{ fontSize: "0.9rem" }} />
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "#f2f2f2",
  flexDirection: "row",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
  borderRadius: "7px",
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));
//--------------------------------------------------------

var paydunya = require("paydunya");

var setup = new paydunya.Setup({
  masterKey: "DNrUjYFV-6HiQ-NNu5-DAgU-WizSswjzZW6I",
  privateKey: "test_private_Qyqe0MaokWBMmU1m5d1kNHcj7fs",
  publicKey: "test_public_8FAyuNRDgwV2v8Yujc8QHvlYtuS",
  token: "vT8PF5dQR3zx7r9OcplJ",
  mode: "test",
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
};

export default function SiteGenerate() {
  // quill rich text
  const modules = {
    toolbar: [
      // [{ 'font': [] }],
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      [{ align: [] }, { color: [] }, { background: [] }], // dropdown with defaults from theme
      ["clean"],
    ],
  };

  const formats = [
    // 'font',
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "align",
    "color",
    "background",
  ];
  const navigate = useNavigate();
  const { eventID } = useParams();
  const { trackingID } = useParams();
  const { plateforme } = useParams();
  const { trackingSelling } = useParams();
  const [sellingID, setSellingID] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [tickets, setTicket] = React.useState([]);
  const [ticketsList, setTicketList] = React.useState([]);
  const [seances, setSeance] = React.useState([]);
  const [seanceActive, setSeanceActive] = React.useState(0);
  const [event, setEvent] = React.useState();
  const [refreh, setRefresh] = React.useState(false);
  const [ticketTotals, setTicketTotal] = React.useState(0);
  const [quantite, setQuantite] = React.useState([]);
  const [montant, setMontant] = React.useState(0);
  const [reserve, setReserve] = React.useState(false);
  const [org, setOrg] = React.useState([]);
  const [infoUser, setInfoUser] = React.useState([]);
  const [isFollow, setIsFollow] = React.useState(false);
  const [addDiscount, setAddDiscount] = React.useState(false);
  const [discountField, setDiscountField] = React.useState("");
  const [discount, setDiscount] = React.useState();
  const [discountMsg, setDiscountMsg] = React.useState("");
  const [startVerifyDiscount, setStartVerifyDiscount] = React.useState(false);
  const [discountLoad, setDiscountLoad] = React.useState(false);
  const [desc, setDesc] = useState("");
  const { user } = UserAuth();
  const [isSticky, setSticky] = useState(0);
  const ref = useRef(null);
  const handleScroll = () => {
    if (ref.current) {
      setSticky(ref.current.getBoundingClientRect().top <= 0);
    }
  };

  useEffect(() => {
    console.log("ok");
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", () => handleScroll);
    };
  }, []);

  // reserve field

  const [moyenPaie, setMoyenpaie] = React.useState(0);
  const handleRadioChange = (event) => {
    console.log(event.target.value);
    setMoyenpaie(event.target.value);
  };

  React.useEffect(() => {
    (async () => {
      console.log(
        "start____________________________________________________________"
      );
      try {
        setQuantite([]);
        setLoading(true);
        // const q = query(collection(db, `evenement/${eventID}/tickets`));
        const docRef = doc(db, "evenement", `${eventID}`);
        const docSnap = await getDoc(docRef);

        // console.log(docSnap.data());
        if (docSnap.exists()) {
          setEvent(docSnap.data());
          const querySnapshot1 = await getDoc(
            doc(db, "organisations", docSnap.data().orgId)
          );
          setOrg(querySnapshot1.data());

          //trackinnng
          if (trackingID && plateforme) {
            console.log("tracking...............");
            console.log(docSnap.data().orgId);
            console.log(eventID);
            const snapshotfb = await getDoc(
              doc(
                db,
                `organisations/${docSnap.data().orgId}/sosm/${
                  docSnap.data().orgId
                }/${eventID}`,
                `facebook`
              )
            );
            if (snapshotfb.exists()) {
              console.log(snapshotfb);
              if (snapshotfb.data().code === trackingID) {
                await updateDoc(
                  doc(
                    db,
                    `organisations/${docSnap.data().orgId}/sosm/${
                      docSnap.data().orgId
                    }/${eventID}`,
                    `${plateforme === "fb" ? "facebook" : ""}`
                  ),
                  {
                    click: increment(1),
                  }
                );
                navigate(`/fr/event/${docSnap.id}/fb-${trackingID}`);
              } else {
                console.log("tracking id not corresponding");
              }
            }
          }

          //tracking selling
          if (trackingSelling) {
            console.log("tracking selling ...............");
            const tmp = trackingSelling.split("-");
            const snapshotfb = await getDoc(
              doc(
                db,
                `organisations/${docSnap.data().orgId}/sosm/${
                  docSnap.data().orgId
                }/${eventID}`,
                `facebook`
              )
            );
            if (snapshotfb.exists()) {
              console.log(snapshotfb.data().code);
              if (snapshotfb.data().code === tmp[1]) {
                setSellingID(trackingSelling);
              } else {
                console.log("tracking id not corresponding");
              }
            }
          }

          if (docSnap.data().seanceActive === 1) {
            const tmpdate = new Date(
              format(new Date(Date.now()), "yyyy-MM-dd")
            );
            //const tmpdate = new Date(Date.now())
            console.log(tmpdate);
            const q1 = query(
              collection(db, `evenement/${eventID}/seances`),
              where("date", ">=", tmpdate),
              orderBy("date", "asc")
            );
            const snapshot1 = await getDocs(q1);
            setSeance([]);
            snapshot1.forEach((d) => {
              setSeance((seances) => [...seances, d]);
            });
            snapshot1.docs[0].data().billets.forEach(async (billet) => {
              if (billet.status === 1) {
                const snapshot = await getDoc(
                  doc(db, `evenement/${eventID}/tickets`, `${billet.id}`)
                );
                if (snapshot.exists()) {
                  setTicket([]);
                  // console.log("Mes billet", snapshot.data().ticket_name);
                  setTicket((tickets) => [...tickets, snapshot]);
                  setQuantite((quantite) => [...quantite, 0]);
                }
              }
            });
          } else {
            console.log(
              "start1____________________________________________________________",
              tickets.length
            );
            const q = query(
              collection(db, `evenement/${eventID}/tickets`),
              where("status", "==", 1)
            );
            const snapshot = await getDocs(q);
            console.log(
              "snap__________________________________________________________",
              snapshot.size
            );
            setTicket([]);
            snapshot.forEach((d) => {
              console.log(
                "start2____________________________________________________________",
                tickets.length
              );

              setTicket((tickets) => [...tickets, d]);
              setQuantite((quantite) => [...quantite, 0]);
            });
          }
        }
        if (user) {
          console.log(user.uid);
          const userRef = await getDoc(doc(db, "users", `${user.uid}`));
          setInfoUser(userRef);
        }
        const q = query(collection(db, `evenement/${eventID}/description`));
        const snapshot = await getDocs(q);
        snapshot.forEach((d) => {
          // setDescription((description) => [d]);
          // setDesc(d.data().valeur);
          console.log("desc : ", d.data());
          if (d.data().valeur) {
            // setEditorState(() => EditorState.createWithContent(convertFromHTML(d.data().valeur)));
            setDesc(d.data().valeur);
          } else {
            // setEditorState(() => EditorState.createWithContent(convertFromHTML(d.data().value)));
            setDesc(d.data().value);
          }
        });

        // setLoading(false);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    })();
  }, [refreh, user]);

  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const u1 = await getDoc(
          doc(db, `users/${user.uid}/followings`, event.orgId)
        );
        if (u1.exists()) {
          setIsFollow(true);
        } else {
          setIsFollow(false);
        }
      } catch (error) {
        console.log(error.msg);
      }
      setLoading(false);
    })();
  }, [refreh, event]);

  React.useEffect(() => {
    (async () => {
      VerifyDiscount();
    })();
  }, [startVerifyDiscount, ticketTotals]);

  React.useEffect(() => {
    (async () => {
      setOpenContact(false);
      setOpenReservation(false);
    })();
  }, []);

  // loading of customization
  // header

  // part 2
  React.useEffect(() => {
    (async () => {})();
  }, []);

  const addTicket = (index) => {
    console.log("index : ", index);
    var tmp = montant;
    tmp = tmp + tickets[index].data().price;
    setMontant(tmp);
    setTicketTotal(parseInt(ticketTotals + 1));
    const tmp1 = { ...quantite };
    tmp1[index] += 1;
    setQuantite(tmp1);
    // new implementation
    if (ticketsList.length === 0) {
      setTicketList([
        {
          id: tickets[index].id,
          nom: tickets[index].data().ticket_name,
          prix: tickets[index].data().price,
          quantite: parseInt(1),
        },
      ]);
    } else {
      const exist = ticketsList.findIndex(({ id }) => id === tickets[index].id);
      if (exist === -1) {
        setTicketList((existItem) => {
          return [
            ...existItem.slice(0, ticketsList.length),
            {
              id: tickets[index].id,
              nom: tickets[index].data().ticket_name,
              prix: tickets[index].data().price,
              quantite: parseInt(1),
            },
            ...existItem.slice(ticketsList.length + 1),
          ];
        });
      } else {
        setTicketList((existItem) => {
          return [
            ...existItem.slice(0, exist),
            {
              id: tickets[index].id,
              nom: tickets[index].data().ticket_name,
              prix: tickets[index].data().price,
              quantite: parseInt(ticketsList[exist].quantite + 1),
            },
            ...existItem.slice(exist + 1),
          ];
        });
      }
    }

    console.log(ticketsList);
  };

  const removeTicket = (index) => {
    if (quantite[index] > 0) {
      var tmp = montant;
      tmp = tmp - tickets[index].data().price;
      setMontant(tmp);
      setTicketTotal(parseInt(ticketTotals - 1));
      const tmp1 = { ...quantite };
      tmp1[index] -= 1;
      setQuantite(tmp1);

      const exist = ticketsList.findIndex(({ id }) => id === tickets[index].id);
      console.log(exist);
      if (exist !== -1) {
        if (ticketsList[exist].quantite === 1) {
          setTicketList((existItem) => {
            return [
              ...existItem.slice(0, exist),
              ...existItem.slice(exist + 1),
            ];
          });
        } else {
          setTicketList((existItem) => {
            return [
              ...existItem.slice(0, exist),
              {
                id: tickets[index].id,
                nom: tickets[index].data().ticket_name,
                prix: tickets[index].data().price,
                quantite: parseInt(ticketsList[exist].quantite - 1),
              },
              ...existItem.slice(exist + 1),
            ];
          });
        }
      }
      console.log(ticketsList);
    }
  };

  // application de code promo
  const VerifyDiscount = async () => {
    setDiscountLoad(true);
    if (!addDiscount || discountField === "") {
      return;
    }
    const q = query(
      collection(db, `evenement/${eventID}/codePromo`),
      where("code", "==", discountField)
    );
    const snapshot = await getDocs(q);
    if (snapshot.docs.length !== 0) {
      // console.log("Mes billet", snapshot.data());
      if (
        snapshot.docs[0].data().usage.max > snapshot.docs[0].data().usage.now
      ) {
        if (snapshot.docs[0].data().billet.length === 0) {
          // la reduction n'est pas limité à un billet
          if (snapshot.docs[0].data().reduc.type === "percent") {
            if (ticketsList.length === 1) {
              setDiscountMsg("Code validé");
              setDiscount({
                id: snapshot.docs[0].id,
                value:
                  (ticketsList[0].prix * snapshot.docs[0].data().reduc.value) /
                  100,
              });
            } else if (ticketsList.length > 1) {
              const ticketListTmp = ticketsList;
              ticketListTmp.sort((a, b) => a.prix - b.prix);
              var index = 0;
              for (let i = 0; i < ticketListTmp.length; i++) {
                const element = ticketListTmp[i];
                if (ticketListTmp[i].prix === 0 && ticketListTmp.length > 1) {
                  index += 1;
                } else {
                  break;
                }
              }
              setDiscount({
                id: snapshot.docs[0].id,
                value:
                  (ticketListTmp[index].prix *
                    snapshot.docs[0].data().reduc.value) /
                  100,
              });
              setDiscountMsg(
                "Code validé ! Cependant il ne s'applique qu'a un seul billet"
              );
            }
          } else {
            if (ticketTotals > 1) {
              setDiscountMsg(
                "Code validé ! Cependant il ne s'applique qu'a un seul billet"
              );
            } else {
              setDiscountMsg("Code validé");
            }
            setDiscount({
              id: snapshot.docs[0].id,
              value: snapshot.docs[0].data().reduc.value,
            });
          }
        } else {
          // la reduction peut etre limité à certains billet
          let find = 0;
          let findTab = [];
          console.log(ticketsList);
          console.log(snapshot.docs[0].data().billet);
          for (
            let index = 0;
            index < snapshot.docs[0].data().billet.length;
            index++
          ) {
            const id = snapshot.docs[0].data().billet[index];
            const exist = ticketsList.findIndex(
              ({ id }) => id === snapshot.docs[0].data().billet[index]
            );
            console.log(exist);
            if (exist !== -1) {
              findTab.push(ticketsList[exist]);
              find = 1;
            }
          }

          if (find === 1) {
            console.log(findTab);
            if (snapshot.docs[0].data().reduc.type === "percent") {
              if (findTab.length === 1) {
                setDiscountMsg("Code validé");
                setDiscount({
                  id: snapshot.docs[0].id,
                  value:
                    (findTab[0].prix * snapshot.docs[0].data().reduc.value) /
                    100,
                });
              } else if (findTab.length > 1) {
                const ticketListTmp = findTab;
                ticketListTmp.sort((a, b) => a.prix - b.prix);
                setDiscount({
                  id: snapshot.docs[0].id,
                  value:
                    (ticketListTmp[0].prix *
                      snapshot.docs[0].data().reduc.value) /
                    100,
                });
                setDiscountMsg(
                  "Code validé ! Cependant il ne s'applique qu'a un seul billet"
                );
              }
            } else {
              if (ticketTotals > 1) {
                setDiscountMsg(
                  "Code validé ! Cependant il ne s'applique qu'a un seul billet"
                );
              } else {
                setDiscountMsg("Code validé");
              }
              setDiscount({
                id: snapshot.docs[0].id,
                value: snapshot.docs[0].data().reduc.value,
              });
            }
          } else {
            setDiscountMsg("Code non valable");
          }
        }
      } else {
        console.log("code non valable");
        setDiscountMsg("code non valable");
      }
    } else {
      console.log("code non valable");
      setDiscountMsg("Code non valable");
    }
    setDiscountLoad(false);
  };

  // if seance est active
  const changeSeance = (index) => {
    setTicketTotal(0);
    setTicketList([]);
    setOpenContact(false);
    setTicket([]);
    setQuantite([]);
    setSeanceActive(index);
    seances[index].data().billets.forEach(async (billet) => {
      if (billet.status === 1) {
        const snapshot = await getDoc(
          doc(db, `evenement/${eventID}/tickets`, `${billet.id}`)
        );
        if (snapshot.exists()) {
          // console.log("Mes billet", snapshot.data());
          setTicket((tickets) => [...tickets, snapshot]);
          setQuantite((quantite) => [...quantite, 0]);
        }
      }
    });
  };
  const handleTimestamp = (e) => {
    if (e) {
      const date = e.toDate();
      const tmp = date.toJSON().split(".");
      return tmp[0];
    } else {
      return "24/02/2013";
    }
  };

  const smUp = useResponsive("up", "sm");

  const mdUp = useResponsive("up", "md");
  // Modal
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // modal contact

  const handleOpenContact = () => {
    setOpenContact(true);
  };
  const handleCloseContact = () => {
    setOpenContact(false);
  };

  const {
    openContact,
    setOpenContact,
    openReservation,
    setOpenReservation,
    openLogin,
    setOpenLogin,
  } = Variables();

  // follow

  const follow = async () => {
    if (user) {
      var exist;
      console.log(user.email);
      console.log(infoUser);
      setLoading(true);
      try {
        const u1 = await getDoc(
          doc(db, `users/${user.uid}/followings`, event.orgId)
        );
        if (u1.exists()) {
          exist = 1;
        } else {
          exist = -1;
        }

        if (exist === -1) {
          // abonnement
          const u2 = await getDoc(
            doc(
              db,
              `organisations/${event.orgId}/crm/${event.orgId}/contacts`,
              user.email
            )
          );
          if (u2.exists()) {
            await updateDoc(
              doc(
                db,
                `organisations/${event.orgId}/crm/${event.orgId}/contacts`,
                user.email
              ),
              {
                nom: infoUser.data().nom,
                prenom: infoUser.data().prenom,
                push: true,
                newslettres: true,
              }
            );
          } else {
            await setDoc(
              doc(
                db,
                `organisations/${event.orgId}/crm/${event.orgId}/contacts`,
                user.email
              ),
              {
                uid: user.uid,
                nom: infoUser.data().nom,
                prenom: infoUser.data().prenom,
                push: true,
                newslettres: true,
              }
            );
          }

          await setDoc(doc(db, `users/${user.uid}/followings/${event.orgId}`), {
            push: true,
            newslettres: true,
          });
        } else {
          // desabonnement
          await updateDoc(
            doc(
              db,
              `organisations/${event.orgId}/crm/${event.orgId}/contacts`,
              user.email
            ),
            {
              uid: user.uid,
              nom: infoUser.data().nom,
              prenom: infoUser.data().prenom,
              push: false,
              newslettres: false,
            }
          );

          await deleteDoc(doc(db, `users/${user.uid}/followings`, event.orgId));
        }

        // setConfirm(true);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
      setRefresh(!refreh);
    } else {
      setOpenLogin(true);
    }
  };
  return (
    <>
      {event ? (
        <>
          {event && (
            <>
              <Box
                sx={{
                  backgroundImage: `url(${event.idVisuel})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  height: "100dvh",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "end",
                  display: "flex",
                  padding: 5,
                }}
              >
                <Stack
                  sx={{
                    // backgroundColor:'linear-gradient(rgba(0, 0, 255, 0.5), rgba(255, 255, 0, 0.5))',
                    display: "flex",
                    alignItems: "center",
                    width: "50%",
                    justifyContent: "center",
                    bgcolor: "rgba(245, 245, 245, 0.60)",
                    padding: 2,
                    borderRadius: "10px",
                    // boxShadow: '-1em 0 .9em white'
                  }}
                >
                  <Typography align="center" fontSize={80} fontWeight={1000}>
                    {event.nomEvent}
                  </Typography>
                  <Typography align="center" fontSize={35} fontWeight={500}>
                    {event.dateDebut &&
                      format(
                        new Date(handleTimestamp(event.dateDebut)),
                        "EEE dd MMM yy ",
                        { locale: fr }
                      )}{" "}
                    {event.dateDebut &&
                      format(new Date(handleTimestamp(event.dateDebut)), "hh", {
                        locale: fr,
                      })}
                    h
                    {event.dateDebut &&
                      format(new Date(handleTimestamp(event.dateDebut)), "mm", {
                        locale: fr,
                      })}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      size="large"
                      sx={{
                        textTransform: "initial",
                        border: "2px solid transparent",
                        color: "white",
                        backgroundColor: "black",
                        borderRadius: "7px",
                        "&:hover": {
                          backgroundColor: "white",
                          color: "black",
                          borderColor: "black",
                        },
                      }}
                      variant="contained"
                      onClick={handleOpenContact}
                    >
                      reservez votre ticket
                    </Button>
                  </Box>
                </Stack>
              </Box>
              {/** Part 2 */}
              <Container>
                <Box
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    paddingX: 2,
                  }}
                >
                  <Typography align="center" fontSize={90} fontWeight={1000}>
                    Le concert de l'année
                  </Typography>
                </Box>
                <Box
                  sx={{
                    // bgcolor: "rgba(245, 245, 245, 0.60)",

                    width: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    padding: 2,
                    paddingY: 10,
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Typography
                        paddingTop={2}
                        paddingBottom={2}
                        variant="h5"
                        fontWeight={600}
                      >
                        Description
                      </Typography>
                      {desc !== "" ? (
                        <Accordion>
                          <AccordionSummary
                            expandIcon={
                              <Iconify icon={"fluent:add-28-filled"} />
                            }
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography fontSize={18} color="#999999">
                              Voir la description
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography fontSize={18} color="#999999">
                              <ReactQuill
                                theme="bubble"
                                readOnly={true}
                                //modules={modules}
                                formats={formats}
                                value={desc}
                                //onChange={handleChangeQuill}
                              />
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                      ) : (
                        <Typography fontSize={18} color="#999999">
                          Aucune description renseignée par l'organisateur !
                        </Typography>
                      )}
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Typography
                        paddingTop={2}
                        paddingBottom={2}
                        variant="h5"
                        fontWeight={600}
                      >
                        Location
                      </Typography>
                      <Typography fontSize={18} color="#999999">
                        {event.lieu_event}, {event.city}, {event.country}
                      </Typography>
                      <Typography fontSize={16} color="#999999">
                        {event.infoLieu}
                      </Typography>
                      <Box sx={{ width: "100%" }}>
                        <Maps centerC={event.localisation} height={400} />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Container>

              {/** Part 3 */}
              <Box sx={{ bgcolor: "#f2f2f2" }}>
                <Container>
                  <Box paddingX={2} paddingY={15}>
                    <Typography variant="h5" fontWeight={600}>
                      Reservez votre place
                    </Typography>
                    <Typography fontSize={18} color="#999999">
                      Choisissez vos tickets
                    </Typography>
                    <Grid spacing={5} container paddingY={5}>
                      {seances.length !== 0 && smUp && (
                        <Grid item xs={12}>
                          <Typography
                            paddingBottom={2}
                            variant="h5"
                            fontWeight={600}
                          >
                            Choisissez votre date {}
                          </Typography>
                          <Stack direction="row" spacing={2}>
                            {seances.map((seance, index) =>
                              seanceActive === index ? (
                                <Box
                                  padding={2}
                                  sx={{
                                    borderRadius: "7px",
                                    border: "2px solid transparent",
                                    cursor: "pointer",
                                    bgcolor: "#F2ECFD",
                                    borderColor: "#5E17EB",
                                    maxWidth: 80,
                                    "&:hover": {
                                      borderColor: "#5E17EB",
                                    },
                                  }}
                                  onClick={() => changeSeance(index)}
                                >
                                  {console.log(seance.data())}
                                  <Typography
                                    textAlign="center"
                                    sx={{ textTransform: "uppercase" }}
                                  >
                                    {format(
                                      new Date(
                                        handleTimestamp(seance.data().date)
                                      ),
                                      "EEE dd MMM",
                                      { locale: fr }
                                    )}
                                  </Typography>
                                </Box>
                              ) : (
                                <Box
                                  padding={2}
                                  sx={{
                                    borderRadius: "7px",
                                    border: "2px solid transparent",
                                    cursor: "pointer",
                                    bgcolor: "#F2ECFD",
                                    maxWidth: 80,
                                    "&:hover": {
                                      borderColor: "#5E17EB",
                                    },
                                  }}
                                  onClick={() => changeSeance(index)}
                                >
                                  <Typography
                                    textAlign="center"
                                    sx={{ textTransform: "uppercase" }}
                                  >
                                    {format(
                                      new Date(
                                        handleTimestamp(seance.data().date)
                                      ),
                                      "EEE dd MMM",
                                      { locale: fr }
                                    )}
                                  </Typography>
                                </Box>
                              )
                            )}
                          </Stack>
                        </Grid>
                      )}

                      <Grid item xs={12} md={7}>
                        {smUp && (
                          <>
                            <Grid container spacing={3}>
                              {tickets.length === 0 && loading ? (
                                <>
                                  <Grid item xs={12} md={12}>
                                    <Skeleton
                                      variant="rectangular"
                                      height={150}
                                    />
                                  </Grid>
                                  <Grid item xs={12} md={12}>
                                    <Skeleton
                                      variant="rectangular"
                                      height={150}
                                    />
                                  </Grid>
                                </>
                              ) : tickets.length === 0 && !loading ? (
                                <Grid xs={12} md={12}>
                                  <Stack
                                    paddingTop={4}
                                    paddingX={4}
                                    spacing={3}
                                    direction="row"
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                  >
                                    <Typography
                                      variant="h7"
                                      fontWeight={600}
                                      color="#999999"
                                    >
                                      Aucun billet mise à disposition par{" "}
                                      {org.nomOrg} pour l'instant, mais vous
                                      pourriez être intéressé d'autres
                                      événements.
                                    </Typography>
                                  </Stack>
                                </Grid>
                              ) : (
                                tickets.map((ticket, index) => (
                                  <Grid item xs={12} md={12}>
                                    <Box
                                      sx={{
                                        padding: 4,
                                        bgcolor: "#999999",
                                        color: "white",
                                        borderRadius: "7px",
                                      }}
                                    >
                                      <Grid
                                        container
                                        justifyContent="space-between"
                                      >
                                        <Grid>
                                          <Typography
                                            fontSize={18}
                                            fontWeight={600}
                                          >
                                            {ticket.data().ticket_name}
                                          </Typography>
                                          <Typography fontSize={18}>
                                            {ticket.data().price === 0
                                              ? "Gratuit"
                                              : ticket.data().price + " CFA"}
                                          </Typography>
                                        </Grid>
                                        {seances.length !== 0 ? (
                                          <Grid>
                                            {seances[seanceActive].data()
                                              .billets[
                                              seances[seanceActive]
                                                .data()
                                                .billets.findIndex(
                                                  ({ id }) => id === ticket.id
                                                )
                                            ].quota ===
                                              seances[seanceActive].data()
                                                .billets[
                                                seances[seanceActive]
                                                  .data()
                                                  .billets.findIndex(
                                                    ({ id }) => id === ticket.id
                                                  )
                                              ].sell &&
                                            seances[seanceActive].data()
                                              .billets[
                                              seances[seanceActive]
                                                .data()
                                                .billets.findIndex(
                                                  ({ id }) => id === ticket.id
                                                )
                                            ].sell !== 0 ? (
                                              <Box
                                                component="button"
                                                padding={0.5}
                                                sx={{
                                                  borderRadius: "5px",
                                                  fontSize: 11,
                                                  backgroundColor: "#FBE79D",
                                                  border: "1px dashed #FBE79D",
                                                }}
                                              >
                                                épuisé
                                              </Box>
                                            ) : (
                                              <Stack
                                                direction="row"
                                                spacing={1}
                                              >
                                                {quantite[index] === 0 ? (
                                                  <IconButton
                                                    size="small"
                                                    disabled
                                                    aria-label="fingerprint"
                                                    sx={{
                                                      bgcolor: "#5E17EB",
                                                      color: "white",
                                                    }}
                                                  >
                                                    <Iconify icon="ic:baseline-minus" />
                                                  </IconButton>
                                                ) : (
                                                  <IconButton
                                                    onClick={() =>
                                                      removeTicket(index)
                                                    }
                                                    size="small"
                                                    aria-label="fingerprint"
                                                    sx={{
                                                      bgcolor: "#5E17EB",
                                                      color: "white",
                                                    }}
                                                  >
                                                    <Iconify icon="ic:baseline-minus" />
                                                  </IconButton>
                                                )}
                                                <Typography fontSize={18}>
                                                  {quantite[index]}
                                                </Typography>
                                                <IconButton
                                                  onClick={() =>
                                                    addTicket(index)
                                                  }
                                                  size="small"
                                                  aria-label="fingerprint"
                                                  sx={{
                                                    bgcolor: "#5E17EB",
                                                    color: "white",
                                                  }}
                                                >
                                                  <Iconify icon="material-symbols:add" />
                                                </IconButton>
                                              </Stack>
                                            )}
                                          </Grid>
                                        ) : (
                                          <Grid>
                                            {ticket.data().quota ===
                                              ticket.data().sell &&
                                            ticket.data().sell !== 0 ? (
                                              <Box
                                                component="button"
                                                padding={0.5}
                                                sx={{
                                                  borderRadius: "5px",
                                                  fontSize: 11,
                                                  backgroundColor: "#FBE79D",
                                                  border: "1px dashed #FBE79D",
                                                }}
                                              >
                                                épuisé
                                              </Box>
                                            ) : (
                                              <Stack
                                                direction="row"
                                                spacing={1}
                                              >
                                                {quantite[index] === 0 ? (
                                                  <IconButton
                                                    size="small"
                                                    disabled
                                                    aria-label="fingerprint"
                                                    sx={{
                                                      bgcolor: "#5E17EB",
                                                      color: "white",
                                                    }}
                                                  >
                                                    <Iconify icon="ic:baseline-minus" />
                                                  </IconButton>
                                                ) : (
                                                  <IconButton
                                                    onClick={() =>
                                                      removeTicket(index)
                                                    }
                                                    size="small"
                                                    aria-label="fingerprint"
                                                    sx={{
                                                      bgcolor: "#5E17EB",
                                                      color: "white",
                                                    }}
                                                  >
                                                    <Iconify icon="ic:baseline-minus" />
                                                  </IconButton>
                                                )}
                                                <Typography fontSize={18}>
                                                  {quantite[index]}
                                                </Typography>
                                                <IconButton
                                                  onClick={() =>
                                                    addTicket(index)
                                                  }
                                                  size="small"
                                                  aria-label="fingerprint"
                                                  sx={{
                                                    bgcolor: "#5E17EB",
                                                    color: "white",
                                                  }}
                                                >
                                                  <Iconify icon="material-symbols:add" />
                                                </IconButton>
                                              </Stack>
                                            )}
                                          </Grid>
                                        )}
                                      </Grid>
                                      {ticket.data().description &&
                                        ticket.data().description !== "" && (
                                          <Grid>
                                            <Box>
                                              <Accordion
                                                elevation={0}
                                                sx={{
                                                  backgroundColor: "#999999",
                                                }}
                                              >
                                                <AccordionSummary
                                                  expandIcon={
                                                    <Iconify icon="ep:arrow-down-bold" />
                                                  }
                                                  aria-controls="panel1a-content"
                                                  id="panel1a-header"
                                                >
                                                  <Typography
                                                    fontSize={18}
                                                    sx={{ color: "white" }}
                                                  >
                                                    Info sur le billet
                                                  </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails
                                                  sx={{
                                                    backgroundColor: "white",
                                                    borderRadius: "7px",
                                                  }}
                                                >
                                                  <Typography>
                                                    {ticket.data().description}
                                                  </Typography>
                                                </AccordionDetails>
                                              </Accordion>
                                            </Box>
                                          </Grid>
                                        )}
                                    </Box>
                                  </Grid>
                                ))
                              )}
                            </Grid>
                          </>
                        )}
                      </Grid>

                      {/** bouton utilisé sur mobile */}
                      {!smUp && (
                        <Card
                          elevation={5}
                          sx={{
                            position: "fixed",
                            bottom: 0,
                            left: 0,
                            right: 0,
                            paddingX: 5,
                            paddingY: 2,
                          }}
                        >
                          <Typography
                            fontSize={18}
                            align="center"
                            paddingBottom={2}
                          >
                            à partir de 5000CFA
                          </Typography>
                          <Button
                            size="large"
                            fullWidth
                            sx={{
                              textTransform: "initial",
                              border: "2px solid transparent",
                              color: "white",
                              backgroundColor: "black",
                              borderRadius: "7px",
                              "&:hover": {
                                backgroundColor: "white",
                                color: "black",
                                borderColor: "black",
                              },
                            }}
                            variant="contained"
                            onClick={handleOpen}
                          >
                            Reservé maintenant
                          </Button>
                        </Card>
                      )}
                      <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box sx={style}>
                          <Stack padding={1} direction="row">
                            <IconButton onClick={handleClose}>
                              <Iconify
                                icon="solar:close-circle-linear"
                                sx={{ width: 24, height: 24 }}
                              />
                            </IconButton>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                width: "100%",
                              }}
                            >
                              <Typography
                                variant="h6"
                                fontWeight={600}
                                textAlign="center"
                                color="#999999"
                              >
                                {event.nomEvent}
                              </Typography>
                            </Box>
                          </Stack>
                          <Divider />
                          <ReserMobile eventID={eventID} />
                        </Box>
                      </Modal>

                      {ticketsList.length !== 0 && smUp ? (
                        <Grid
                          sx={{ position: "", top: "8%", left: "60%" }}
                          item
                          xs={12}
                          md={5}
                        >
                          <Card
                            sx={{
                              padding: 3,
                              border: "2px solid #5E17EB ",
                              borderRadius: "7px",
                            }}
                          >
                            <Typography variant="h5" fontWeight={600}>
                              x{ticketTotals}
                            </Typography>
                            <Typography fontSize={18}>
                              Billets selectionné(s)
                            </Typography>
                            <Stack marginY={2} spacing={1}>
                              {ticketsList.map((billet, index) => (
                                <Box bgcolor="gainsboro" padding={2}>
                                  <Typography>{billet.nom}</Typography>
                                  <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                  >
                                    <Typography fontSize={18}>
                                      {billet.quantite}
                                    </Typography>
                                    <Typography fontSize={18}>
                                      {billet.prix === 0
                                        ? "Gratuit"
                                        : billet.prix + " CFA"}
                                    </Typography>
                                  </Stack>
                                </Box>
                              ))}
                            </Stack>

                            <Divider />
                            <Stack
                              paddingY={2}
                              spacing={1}
                              direction="column"
                              justifyContent="space-between"
                            >
                              {addDiscount ? (
                                <>
                                  {discountMsg !== "" &&
                                    discountMsg.includes("Code validé") && (
                                      <Typography
                                        color="green"
                                        fontSize={14}
                                        textAlign="center"
                                      >
                                        {discountMsg}
                                      </Typography>
                                    )}
                                  {discountMsg !== "" &&
                                    discountMsg.includes(
                                      "Code non valable"
                                    ) && (
                                      <Typography
                                        color="red"
                                        fontSize={14}
                                        textAlign="center"
                                      >
                                        {discountMsg}
                                      </Typography>
                                    )}
                                  <TextField
                                    size="small"
                                    value={discountField}
                                    onChange={(e) => {
                                      setDiscountField(e.target.value);
                                    }}
                                  />
                                  <Button
                                    size="large"
                                    fullWidth
                                    sx={{
                                      textTransform: "initial",
                                      border: "2px solid transparent",
                                      color: "white",
                                      backgroundColor: "black",
                                      borderRadius: "7px",
                                      "&:hover": {
                                        backgroundColor: "white",
                                        color: "black",
                                        borderColor: "black",
                                      },
                                    }}
                                    disabled={
                                      discountField === "" ? true : false
                                    }
                                    variant="contained"
                                    onClick={() => {
                                      setStartVerifyDiscount(
                                        !startVerifyDiscount
                                      );
                                    }}
                                  >
                                    Valider
                                  </Button>
                                </>
                              ) : (
                                <Button
                                  size="large"
                                  fullWidth
                                  sx={{
                                    textTransform: "initial",
                                    border: "2px solid transparent",
                                    color: "white",
                                    backgroundColor: "black",
                                    borderRadius: "7px",
                                    "&:hover": {
                                      backgroundColor: "white",
                                      color: "black",
                                      borderColor: "black",
                                    },
                                  }}
                                  variant="contained"
                                  onClick={() => {
                                    setAddDiscount(true);
                                  }}
                                >
                                  Ajouter un code promo
                                </Button>
                              )}
                            </Stack>
                            <Divider />
                            <Stack
                              paddingY={2}
                              direction="column"
                              justifyContent="center"
                            >
                              {discount && (
                                <>
                                  <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                  >
                                    <Typography fontSize={16} fontWeight={500}>
                                      Total hors réduction :
                                    </Typography>
                                    <Typography fontSize={16} fontWeight={500}>
                                      {montant} CFA{" "}
                                    </Typography>
                                  </Stack>
                                  <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                  >
                                    <Typography fontSize={14} fontWeight={500}>
                                      Réduction :
                                    </Typography>
                                    <Typography fontSize={14} fontWeight={500}>
                                      {discount.value} CFA{" "}
                                    </Typography>
                                  </Stack>
                                </>
                              )}
                              <Stack
                                direction="row"
                                justifyContent="space-between"
                              >
                                <Typography fontSize={18} fontWeight={600}>
                                  Total :
                                </Typography>
                                <Typography fontSize={18} fontWeight={600}>
                                  {discount
                                    ? montant - discount.value
                                    : montant}{" "}
                                  CFA{" "}
                                </Typography>
                              </Stack>
                            </Stack>
                            <Button
                              onClick={() => {
                                setOpenReservation(true);
                              }}
                              size="large"
                              fullWidth
                              sx={{
                                textTransform: "initial",
                                border: "2px solid transparent",
                                color: "white",
                                backgroundColor: "#5E17EB",
                                borderRadius: "7px",
                                "&:hover": {
                                  backgroundColor: "white",
                                  color: "#5E17EB",
                                  borderColor: "#5E17EB",
                                },
                              }}
                              variant="contained"
                            >
                              Reserver
                            </Button>
                            <Modal
                              open={openReservation}
                              aria-labelledby="parent-modal-title"
                              aria-describedby="parent-modal-description"
                            >
                              <ReserDesktop
                                event={event}
                                ticketsList={ticketsList}
                                montant={montant}
                                reduction={discount}
                                seance={
                                  seances.length !== 0
                                    ? seances[seanceActive].id
                                    : null
                                }
                              />
                            </Modal>
                          </Card>
                        </Grid>
                      ) : null}
                    </Grid>
                  </Box>
                </Container>
              </Box>

              {/** Part 4 */}
              <Box
                sx={{
                  bgcolor: "#f2f2f2",
                  borderTop: "2px solid white",
                  borderBlockEnd: "2px solid white",
                  paddingX: 2,
                  paddingY: 5,
                }}
              >
                <Grid
                  container
                  spacing={2}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Grid item>
                    <Box display="flex" justifyContent="center">
                      <Avatar
                        src={event.idVisuel}
                        sx={{ width: 50, height: 50 }}
                      ></Avatar>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box display="flex" justifyContent="center">
                      <Avatar
                        src={event.idVisuel}
                        sx={{ width: 50, height: 50 }}
                      ></Avatar>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box display="flex" justifyContent="center">
                      <Avatar
                        src={event.idVisuel}
                        sx={{ width: 50, height: 50 }}
                      ></Avatar>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </>
          )}

          {/**---------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */}

          <Footer />
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100dvh",
            width: "100%",
          }}
        >
          <CircularProgress sx={{ color: "#5E17EB" }} size={50} />
        </Box>
      )}
    </>
  );
}
