import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Button,
  Card,
  CardContent,
  CardMedia,
  Checkbox,
  CircularProgress,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Modal,
  Radio,
  RadioGroup,
  Skeleton,
  Stack,
  TextField,
} from "@mui/material";
import CustomButton from "../../Components/CustomButton";
import logoImg from "../../media/suspect.jpeg";
import Iconify from "../../Components/Iconify";
import {
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  increment,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../../firebase.config";
import { useNavigate, useParams } from "react-router-dom";
import { borderRadius } from "@mui/system";
import { format } from "date-fns";
import { fr, id } from "date-fns/locale";
import Maps from "../../Components/map/Maps";
import useResponsive from "../../Components/hooks/useResponsive";
import ReserDesktop from "../../Components/reservation/reserDesktop";
import ReserMobile from "../../Components/reservation/resMobile";
import Footer from "../../Components/Footer";
import Contact from "../../Components/Contact";
import { Variables } from "../../context/VariableContext";
import { UserAuth } from "../../context/AuthContext";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
// import * as pay from "https://paydunya.com/assets/psr/js/psr.paydunya.min.js"


export default function EventPageEmbeded() {
  // quill rich text
  const modules = {
    toolbar: [
      // [{ 'font': [] }],
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      [{ align: [] }, { color: [] }, { background: [] }], // dropdown with defaults from theme
      ["clean"],
    ],
  };

  const formats = [
    // 'font',
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "align",
    "color",
    "background",
  ];
  const navigate = useNavigate();
  const { eventID } = useParams();
  const { trackingID } = useParams();
  const { plateforme } = useParams();
  const { trackingSelling } = useParams();
  const { embedParams } = useParams();
  const [sellingID, setSellingID] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [tickets, setTicket] = React.useState([]);
  const [ticketsList, setTicketList] = React.useState([]);
  const [seances, setSeance] = React.useState([]);
  const [seanceActive, setSeanceActive] = React.useState(0);
  const [event, setEvent] = React.useState();
  const [refreh, setRefresh] = React.useState(false);
  const [ticketTotals, setTicketTotal] = React.useState(0);
  const [quantite, setQuantite] = React.useState([]);
  const [montant, setMontant] = React.useState(0);
  const [reserve, setReserve] = React.useState(false);
  const [org, setOrg] = React.useState([]);
  const [infoUser, setInfoUser] = React.useState([]);
  const [isFollow, setIsFollow] = React.useState(false);
  const [addDiscount, setAddDiscount] = React.useState(false);
  const [discountField, setDiscountField] = React.useState("");
  const [discount, setDiscount] = React.useState();
  const [discountMsg, setDiscountMsg] = React.useState("");
  const [startVerifyDiscount, setStartVerifyDiscount] = React.useState(false);
  const [discountLoad, setDiscountLoad] = React.useState(false);

  const [desc, setDesc] = useState("");
  const { user } = UserAuth();
  const [isSticky, setSticky] = useState(0);
  const ref = useRef(null);
  const handleScroll = () => {
    if (ref.current) {
      setSticky(ref.current.getBoundingClientRect().top <= 0);
    }
  };

  useEffect(() => {
    console.log("ok");
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", () => handleScroll);
    };
  }, []);

  // Customization params
  const [textColor, setTextColor] = useState("");
  const [bgColor, setBgColor] = useState("white");
  const [primaryColor, setPrimaryColor] = useState("5E17EB");
  const [secondaryColor, setSecondaryColor] = useState("999999");

  // reserve field

  const [moyenPaie, setMoyenpaie] = React.useState(0);
  const handleRadioChange = (event) => {
    console.log(event.target.value);
    setMoyenpaie(event.target.value);
  };
  function extractStringBetweenChars(str, startChar, endChar) {
    const startIndex = str.indexOf(startChar);
    
    const  endIndex = str.indexOf(endChar, startIndex + 1);
    
    if (startIndex !== -1 && endIndex !== -1) {
      return str.substring(startIndex + startChar.length, endIndex);
    } else if (startIndex !== -1 && endIndex === -1) {
      return str.substring(startIndex + startChar.length);
    } else {
      return ""; // Retourne une chaîne vide si les caractères spécifiés ne sont pas trouvés
    }
  }

  React.useEffect(() => {
    (async () => {
      console.log(
        "start____________________________________________________________"
      );
      try {
        setQuantite([]);
        setLoading(true);
        // const q = query(collection(db, `evenement/${eventID}/tickets`));
        const docRef = doc(db, "evenement", `${eventID}`);
        const docSnap = await getDoc(docRef);

        // console.log(docSnap.data());
        if (docSnap.exists()) {
          setEvent(docSnap.data());
          const querySnapshot1 = await getDoc(
            doc(db, "organisations", docSnap.data().orgId)
          );
          setOrg(querySnapshot1.data());

          //trackinnng
          if (trackingID && plateforme) {
            console.log("tracking...............");
            console.log(docSnap.data().orgId);
            console.log(eventID);
            const snapshotfb = await getDoc(
              doc(
                db,
                `organisations/${docSnap.data().orgId}/sosm/${docSnap.data().orgId
                }/${eventID}`,
                `facebook`
              )
            );
            if (snapshotfb.exists()) {
              console.log(snapshotfb);
              if (snapshotfb.data().code === trackingID) {
                await updateDoc(
                  doc(
                    db,
                    `organisations/${docSnap.data().orgId}/sosm/${docSnap.data().orgId
                    }/${eventID}`,
                    `${plateforme === "fb" ? "facebook" : ""}`
                  ),
                  {
                    click: increment(1),
                  }
                );
                navigate(`/fr/event/${docSnap.id}/fb-${trackingID}`);
              } else {
                console.log("tracking id not corresponding");
              }
            }
          }

          //tracking selling
          if (trackingSelling) {
            console.log("tracking selling ...............");
            const tmp = trackingSelling.split("-");
            const snapshotfb = await getDoc(
              doc(
                db,
                `organisations/${docSnap.data().orgId}/sosm/${docSnap.data().orgId
                }/${eventID}`,
                `facebook`
              )
            );
            if (snapshotfb.exists()) {
              console.log(snapshotfb.data().code);
              if (snapshotfb.data().code === tmp[1]) {
                setSellingID(trackingSelling);
              } else {
                console.log("tracking id not corresponding");
              }
            }
          }

          //
          if (embedParams) {
            console.log("embeded  ...............");

            const tmpBg = extractStringBetweenChars(embedParams, 'bgColor=', '&');
            if (tmpBg !== "") {
              console.log(" bg color ...............");
              console.log(tmpBg);
              console.log(" bg color ...............");
              setBgColor(`${tmpBg}`)
            }
            const tmpPrimary = extractStringBetweenChars(embedParams, 'pColor=', '&');
            if (tmpPrimary !== "") {
              console.log(" primary color ...............");
              console.log(tmpPrimary);
              console.log(" primary color ...............");
              setPrimaryColor(`${tmpPrimary}`)
            }
            const tmpSecondary = extractStringBetweenChars(embedParams, 'sColor=', '&');
            if (tmpSecondary !== "") {

              setSecondaryColor(`${tmpSecondary}`)
            }
          }



          if (docSnap.data().seanceActive === 1) {
            const tmpdate = new Date(
              format(new Date(Date.now()), "yyyy-MM-dd")
            );
            //const tmpdate = new Date(Date.now())
            console.log(tmpdate);
            const q1 = query(
              collection(db, `evenement/${eventID}/seances`),
              where("date", ">=", tmpdate),
              orderBy("date", "asc")
            );
            const snapshot1 = await getDocs(q1);
            setSeance([]);
            snapshot1.forEach((d) => {
              setSeance((seances) => [...seances, d]);
            });
            snapshot1.docs[0].data().billets.forEach(async (billet) => {
              if (billet.status === 1) {
                const snapshot = await getDoc(
                  doc(db, `evenement/${eventID}/tickets`, `${billet.id}`)
                );
                if (snapshot.exists()) {
                  setTicket([]);
                  // console.log("Mes billet", snapshot.data().ticket_name);
                  setTicket((tickets) => [...tickets, snapshot]);
                  setQuantite((quantite) => [...quantite, 0]);
                }
              }
            });
          } else {
            console.log(
              "start1____________________________________________________________",
              tickets.length
            );
            const q = query(
              collection(db, `evenement/${eventID}/tickets`),
              where("status", "==", 1)
            );
            const snapshot = await getDocs(q);
            console.log(
              "snap__________________________________________________________",
              snapshot.size
            );
            setTicket([]);
            snapshot.forEach((d) => {
              console.log(
                "start2____________________________________________________________",
                tickets.length
              );

              setTicket((tickets) => [...tickets, d]);
              setQuantite((quantite) => [...quantite, 0]);
            });
          }
        }
        if (user) {
          console.log(user.uid);
          const userRef = await getDoc(doc(db, "users", `${user.uid}`));
          setInfoUser(userRef);
        }
        const q = query(collection(db, `evenement/${eventID}/description`));
        const snapshot = await getDocs(q);
        snapshot.forEach((d) => {
          // setDescription((description) => [d]);
          // setDesc(d.data().valeur);
          console.log("desc : ", d.data());
          if (d.data().valeur) {
            // setEditorState(() => EditorState.createWithContent(convertFromHTML(d.data().valeur)));
            setDesc(d.data().valeur);
          } else {
            // setEditorState(() => EditorState.createWithContent(convertFromHTML(d.data().value)));
            setDesc(d.data().value);
          }
        });

        // setLoading(false);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    })();
  }, [refreh, user]);

  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const u1 = await getDoc(
          doc(db, `users/${user.uid}/followings`, event.orgId)
        );
        if (u1.exists()) {
          setIsFollow(true);
        } else {
          setIsFollow(false);
        }
      } catch (error) {
        console.log(error.msg);
      }
      setLoading(false);
    })();
  }, [refreh, event]);

  React.useEffect(() => {
    (async () => {
      VerifyDiscount();
    })();
  }, [startVerifyDiscount, ticketTotals]);

  React.useEffect(() => {
    (async () => {
      setOpenContact(false);
      setOpenReservation(false);
    })();
  }, []);

  React.useEffect(() => {
    (async () => {
      if (embedParams) {
        console.log("embeded  ...............");

        const tmpBg = extractStringBetweenChars(embedParams, 'bgColor=', '&');
        if (tmpBg !== "") {
          console.log(" bg color ...............");
          console.log(tmpBg);
          console.log(" bg color ...............");
          setBgColor(`${tmpBg}`)
        }
        const tmpPrimary = extractStringBetweenChars(embedParams, 'pColor=', '&');
        if (tmpPrimary !== "") {
          console.log(" primary color ...............");
          console.log(tmpPrimary);
          console.log(" primary color ...............");
          setPrimaryColor(`${tmpPrimary}`)
        }
        const tmpSecondary = extractStringBetweenChars(embedParams, 'sColor=', '&');
        if (tmpSecondary !== "") {

          setSecondaryColor(`${tmpSecondary}`)
        }
      }
    })();
  }, [embedParams]);

  // application de code promo
  const VerifyDiscount = async () => {
    setDiscountLoad(true);
    if (!addDiscount || discountField === "") {
      return;
    }
    const q = query(
      collection(db, `evenement/${eventID}/codePromo`),
      where("code", "==", discountField)
    );
    const snapshot = await getDocs(q);
    if (snapshot.docs.length !== 0) {
      // console.log("Mes billet", snapshot.data());
      if (
        snapshot.docs[0].data().usage.max > snapshot.docs[0].data().usage.now
      ) {
        if (snapshot.docs[0].data().billet.length === 0) {
          // la reduction n'est pas limité à un billet
          if (snapshot.docs[0].data().reduc.type === "percent") {
            if (ticketsList.length === 1) {
              setDiscountMsg("Code validé");
              setDiscount({
                id: snapshot.docs[0].id,
                value:
                  (ticketsList[0].prix * snapshot.docs[0].data().reduc.value) /
                  100,
              });
            } else if (ticketsList.length > 1) {
              const ticketListTmp = ticketsList;
              ticketListTmp.sort((a, b) => a.prix - b.prix);
              var index = 0;
              for (let i = 0; i < ticketListTmp.length; i++) {
                const element = ticketListTmp[i];
                if (ticketListTmp[i].prix === 0 && ticketListTmp.length > 1) {
                  index += 1;
                } else {
                  break;
                }
              }
              setDiscount({
                id: snapshot.docs[0].id,
                value:
                  (ticketListTmp[index].prix *
                    snapshot.docs[0].data().reduc.value) /
                  100,
              });
              setDiscountMsg(
                "Code validé ! Cependant il ne s'applique qu'a un seul billet"
              );
            }
          } else {
            if (ticketTotals > 1) {
              setDiscountMsg(
                "Code validé ! Cependant il ne s'applique qu'a un seul billet"
              );
            } else {
              setDiscountMsg("Code validé");
            }
            setDiscount({
              id: snapshot.docs[0].id,
              value: snapshot.docs[0].data().reduc.value,
            });
          }
        } else {
          // la reduction peut etre limité à certains billet
          let find = 0;
          let findTab = [];
          console.log(ticketsList);
          console.log(snapshot.docs[0].data().billet);
          for (
            let index = 0;
            index < snapshot.docs[0].data().billet.length;
            index++
          ) {
            const id = snapshot.docs[0].data().billet[index];
            const exist = ticketsList.findIndex(
              ({ id }) => id === snapshot.docs[0].data().billet[index]
            );
            console.log(exist);
            if (exist !== -1) {
              findTab.push(ticketsList[exist]);
              find = 1;
            }
          }

          if (find === 1) {
            console.log(findTab);
            if (snapshot.docs[0].data().reduc.type === "percent") {
              if (findTab.length === 1) {
                setDiscountMsg("Code validé");
                setDiscount({
                  id: snapshot.docs[0].id,
                  value:
                    (findTab[0].prix * snapshot.docs[0].data().reduc.value) /
                    100,
                });
              } else if (findTab.length > 1) {
                const ticketListTmp = findTab;
                ticketListTmp.sort((a, b) => a.prix - b.prix);
                setDiscount({
                  id: snapshot.docs[0].id,
                  value:
                    (ticketListTmp[0].prix *
                      snapshot.docs[0].data().reduc.value) /
                    100,
                });
                setDiscountMsg(
                  "Code validé ! Cependant il ne s'applique qu'a un seul billet"
                );
              }
            } else {
              if (ticketTotals > 1) {
                setDiscountMsg(
                  "Code validé ! Cependant il ne s'applique qu'a un seul billet"
                );
              } else {
                setDiscountMsg("Code validé");
              }
              setDiscount({
                id: snapshot.docs[0].id,
                value: snapshot.docs[0].data().reduc.value,
              });
            }
          } else {
            setDiscountMsg("Code non valable");
          }
        }
      } else {
        console.log("code non valable");
        setDiscountMsg("code non valable");
      }
    } else {
      console.log("code non valable");
      setDiscountMsg("Code non valable");
    }
    setDiscountLoad(false);
  };



  const smUp = useResponsive("up", "sm");

  const mdUp = useResponsive("up", "md");
  // Modal
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // modal contact

  const handleOpenContact = () => {
    setOpenContact(true);
  };
  const handleCloseContact = () => {
    setOpenContact(false);
  };

  const {
    openContact,
    setOpenContact,
    openReservation,
    setOpenReservation,
    openLogin,
    setOpenLogin,
  } = Variables();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    height: "100%",
    bgcolor: `#${bgColor}`,
    border: "px solid transparent",
    boxShadow: 24,
    p: 2,
  };
  return (
    <>
      {event ? (
        <>
          <Container sx={{ paddingY: { xs: 6, sm: 10 } }}>
            <>
              <Box  paddingY={5}>

                <Modal
                  open={true}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <Stack padding={1}>

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          width: "100%",
                        }}
                      >
                        <Typography
                          variant="h6"
                          fontWeight={600}
                          textAlign="center"
                          color={secondaryColor}
                        >
                          {event.nomEvent}
                        </Typography>

                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          width: "100%",
                        }}
                      >
                        <Typography
                          variant="caption"
                          textAlign="center"
                          color={`#${primaryColor}`}
                        >
                          by Choptonticket
                        </Typography>

                      </Box>
                    </Stack>
                    <Divider />
                    <ReserMobile eventID={eventID} />
                  </Box>
                </Modal>
              </Box>

            </>
          </Container>
        </>
      ) : (
        <Box height={'100%'} width={'100%'} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress sx={{ color: `#${primaryColor}` }} />
        </Box>

      )}
    </>
  );
}
