import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Link,
  MenuItem,
  Skeleton,
  Stack,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
} from "firebase/firestore";
import React from "react";
import { db } from "../firebase.config";
import Iconify from "./Iconify";
import { Variables } from "../context/VariableContext";
import Organisateur from "./../pages/organisateur";
import { useParams } from "react-router-dom";
import { useState } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "95%", md: 650 },
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius: "10px",
  boxShadow: 24,
};

const Contact = () => {
  const { openContact, setOpenContact } = Variables();
  const { eventID } = useParams();
  const [event, setEvent] = useState()
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState("");
  const [helpText, setHelp] = React.useState("");
  const [nomField, setNom] = React.useState("");
  const [mailField, setMail] = React.useState("");
  const [raisonField, setRaison] = React.useState("");
  const [messageField, setMessage] = React.useState("");
  const [confirm, setConfirm] = React.useState(false);

  // fonction de contact
  const contact = async () => {
    if (nomField === "" || mailField === "" || messageField === "") {
      setError(true);
      setHelp("Ce champ est obligatoire");
      setErrorMsg("Des erreurs sont survenues avec les informations fournies.");
    } else {
      setLoading(true);
      try {
        console.log(loading);
        await addDoc(collection(db, "contacts"), {
          event:event.nomEvent,
          nom: nomField,
          mail: mailField,
          destinataire : event.orgId,
          objet: "",
          message: messageField,
        });
        setConfirm(true);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    }
  };
  React.useEffect(() => {
    (async () => {
      try {
        console.log(openContact);
        setLoading(true);
        const querySnapshot1 = await getDoc(
          doc(db, "evenement", eventID)
        );
        setEvent(querySnapshot1.data())
        console.log(querySnapshot1.data())
        /* const q = query(
          collection(db, "evenement"),
          orderBy("dateDebut", "asc"),
          limit(6)
        );
        const querySnapshot = await getDocs(q);
        // querySnapshot.empty()
        // console.log(querySnapshot);
        // setEvents(querySnapshot.docs);
        querySnapshot.forEach(async (d) => {
          // doc.data() is never undefined for query doc snapshots
          // console.log(d.id, " => ", d.data());

          // get event organisateur

          const querySnapshot1 = await getDoc(
            doc(db, "organisations", d.data().orgId)
          );
          // console.log('organisation : ', querySnapshot1.data().nomOrg)

          // setLoading(false);
        });
        //setLoading(false); */
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    })();
  }, [openContact]);

  return (
    <Box sx={style}>
      <Box paddingY={2} sx={{ display: "flex", justifyContent: "center" }}>
        <Typography>Contater l'Organisateur</Typography>
      </Box>
      <Divider />
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          {" "}
          <CircularProgress />{" "}
        </Box>
      ) : (
        <>
          {confirm ? (
            <Box >
              <Stack sx={{minHeight:250}} display='flex' justifyContent='center' alignItems='center' spacing={2}>
                <Typography>Votre message a été envoyé à l'organisateur avec succès</Typography>
                <Button
                  sx={{
                    marginRight: 2,
                    textTransform: "initial",
                    border: "2px solid black",
                    color: "black",
                    backgroundColor: "white",
                    borderRadius: "7px",
                    "&:hover": {
                      backgroundColor: "black",
                      color: "white",
                      borderColor: "black",
                    },
                  }}
                  variant="contained"
                  onClick={() => {
                    setOpenContact(false);
                  }}
                >
                  Continuer
                </Button>
              </Stack>
              
            </Box>
          ) : (
            <>
              <Grid padding={4} container spacing={2} rowSpacing={4}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="Votre nom"
                    variant="outlined"
                    value={nomField}
                    onChange={(e) => setNom(e.target.value)}
                    helperText={error && nomField === "" && helpText}
                    error={error && nomField === ""}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="Adresse email"
                    variant="outlined"
                    value={mailField}
                    onChange={(e) => setMail(e.target.value)}
                    helperText={error && mailField === "" && helpText}
                    error={error && mailField === ""}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="outlined-select-currency"
                    fullWidth
                    select
                    label="Raison de la prise de contact"
                    defaultValue={1}
                  >
                    <MenuItem key={1} value={1}>
                      billet
                    </MenuItem>
                    <MenuItem key={2} value={2}>
                      evenement
                    </MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="outlined-basic"
                    fullWidth
                    rows={4}
                    multiline
                    label="Message"
                    variant="outlined"
                    value={messageField}
                    onChange={(e) => setMessage(e.target.value)}
                    helperText={error && messageField === "" && helpText}
                    error={error && messageField === ""}
                  />
                </Grid>
              </Grid>
              <Divider />
              <Box
                paddingX={4}
                paddingBottom={4}
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "end",
                  mt: 2,
                }}
              >
                <Button
                  sx={{
                    marginRight: 2,
                    textTransform: "initial",
                    border: "2px solid black",
                    color: "black",
                    backgroundColor: "white",
                    borderRadius: "7px",
                    "&:hover": {
                      backgroundColor: "black",
                      color: "white",
                      borderColor: "black",
                    },
                  }}
                  variant="contained"
                  onClick={() => {
                    setOpenContact(false);
                  }}
                  startIcon={
                    <Iconify icon="material-symbols:arrow-back-ios-new-rounded" />
                  }
                >
                  Retour
                </Button>
                <Button
                  sx={{
                    textTransform: "initial",
                    border: "2px solid black",
                    color: "black",
                    backgroundColor: "white",
                    borderRadius: "7px",
                    "&:hover": {
                      backgroundColor: "black",
                      color: "white",
                      borderColor: "black",
                    },
                  }}
                  variant="contained"
                  onClick={() => {
                    contact();
                  }}
                >
                  Continuer
                </Button>
              </Box>
            </>
          )}
        </>
      )}
    </Box>
  );
};

export default Contact;
