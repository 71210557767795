import {
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { Box, Container } from "@mui/system";
import React from "react";
import homeIllustration from "../media/app.png";
import CustomButton from "./CustomButton";
import androidBadge from "../media/google-play-badge.png";
import iosBadge from "../media/ios.svg";
import { Padding } from "@mui/icons-material";
import Iconify from "./Iconify";

const GetStarted = () => {
  const CustomContainer = styled(Container)(({ theme }) => ({
    backgroundColor: "#8C52FF",
    borderRadius: "15px",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      height: "auto",
      flexDirection: "column",
      alignItems: "center",
      marginTop: 5,
      padding: theme.spacing(3, 3, 0, 3),
      width: "100%",
    },
  }));

  const CustomBox = styled(Box)(({ theme }) => ({
    padding: theme.spacing(10, 0, 10, 0),
    [theme.breakpoints.down("md")]: {
      padding: "0",
    },
  }));

  return (
    <Box sx={{ mt: 5, backgroundColor: "#8C52FF" }}>
      <Container>
        <CustomBox>
          <CustomContainer>
            <Box>
              <Typography
                sx={{ fontSize: "30px", color: "white", fontWeight: "700" }}
              >
                Découvrez plus de fonctionnalité avec notre application mobile
                Choptonticket
              </Typography>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <Iconify
                      icon={"mingcute:send-fill"}
                      sx={{ color: "white", height: 25, width: 25 }}
                    />
                  </ListItemIcon>
                  <ListItemText>
                    {" "}
                    <Typography
                      sx={{
                        fontSize: "18px",
                        color: "white",
                        my: 1,
                      }}
                    >
                      Transferer vos billets a vos amis
                    </Typography>
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Iconify
                      icon={"healthicons:ok-outline"}
                      sx={{ color: "white", height: 25, width: 25 }}
                    />
                  </ListItemIcon>
                  <ListItemText>
                    {" "}
                    <Typography
                      sx={{
                        fontSize: "18px",
                        color: "white",
                        my: 1,
                      }}
                    >
                      Revendre vos billets en cas de d'imprevu!
                    </Typography>
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Iconify
                      icon={"solar:shop-2-bold"}
                      sx={{ color: "white", height: 25, width: 25 }}
                    />
                  </ListItemIcon>
                  <ListItemText>
                    {" "}
                    <Typography
                      sx={{
                        fontSize: "18px",
                        color: "white",
                        my: 1,
                      }}
                    >
                      Accedez à notre market place pour avoir des billets bon
                      prix
                    </Typography>
                  </ListItemText>
                </ListItem>

                <ListItem>
                  <ListItemIcon>
                    <Iconify
                      icon={"fluent-mdl2:waitlist-confirm"}
                      sx={{ color: "white", height: 25, width: 25 }}
                    />
                  </ListItemIcon>
                  <ListItemText>
                    {" "}
                    <Typography
                      sx={{
                        fontSize: "18px",
                        color: "white",
                        my: 1,
                      }}
                    >
                      Plus de billet disponible à pas soucis, inscrivez vous à
                      la liste d'attente pour être informé lorsqu'une place se
                      libère
                    </Typography>
                  </ListItemText>
                </ListItem>
              </List>
              <Stack direction="row" spacing={2} justifyContent='center'>
                <img
                  src={androidBadge}
                  alt="illustration"
                  style={{ maxWidth: 130 }}
                />
                <img style={{ Width: 150 }} src={iosBadge} alt="Logo" />
              </Stack>
            </Box>
            <Box
              sx={{
                width: { xs: "100%", sm: "50%" },
                height: { xs: "100%", sm: "50%" },
              }}
            >
              <img
                src={homeIllustration}
                alt="illustration"
                style={{ width: "100%" }}
              />
            </Box>
          </CustomContainer>
        </CustomBox>
      </Container>
    </Box>
  );
};

export default GetStarted;
