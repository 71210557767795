import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
  Avatar,
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Skeleton,
} from "@mui/material";
import CustomButton from "../Components/CustomButton";
import logoImg from "../media/suspect.jpeg";
import Footer from "../Components/Footer";
import { UserAuth } from "../context/AuthContext";
import Iconify from "../Components/Iconify";
import { useNavigate } from "react-router-dom";
import Ticket from "../Components/profil/ticket";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { db, functions } from "../firebase.config";
import { httpsCallable } from "firebase/functions";

const options = ["Deconnexion"];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Profile() {
  const { user, logOut } = UserAuth();
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [nom, setNom] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [finish, setFinish] = React.useState(false);
  const [infos, setInfo] = React.useState([]);
  const [prenom, setPrenom] = React.useState("");
  const [mail, setMail] = React.useState("");
  const [urlPhoto, setUrlPhoto] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // log out function
  const handleLogout = async () => {
    try {
      await logOut();
      navigate("/", { replace: true });
    } catch (error) {
      console.log(error);
    }
    setAnchorEl(null);
    // setOpen(null);
  };

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: '#5E17EB',
      },
      children: `${name.split(' ')[0][0]}`,
    };
  }

  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true)
        if (user) {
          console.log(user);
          const u1 = await getDoc(doc(db, "users", `${user.uid}`));
          if (u1.exists()) {
            console.log(u1.data());
            const addMessage = httpsCallable(functions, "retreiveTicket");
            const result = await addMessage({ user: u1.data() });
            console.log(result);
            setNom(u1.data().nom);
            setPrenom(u1.data().prenom);
            setMail(u1.data().email);
            setUrlPhoto(u1.data().photoUrl);
            const q = collection(db, `users/${user.uid}/commandes`);
            const querySnapshot = await getDocs(q);
            // setEvents(querySnapshot.docs);
            querySnapshot.forEach(async (document) => {
              // sort list
              console.log(document.data());
              const element = document.data();
              const refEvent = await getDoc(
                doc(db, "evenement", `${element.event}`)
              );
              element.billets.forEach(async (billet) => {
                const refBillet = await getDoc(
                  doc(
                    db,
                    `evenement/${element.event}/billets`,
                    `${billet.billetID}`
                  )
                );
                if (refBillet.exists) {
                  const tmp = {
                    billet: refBillet.data(),
                    event: refEvent.data(),
                    commande: element,
                  };
                  console.log("tmp : ", tmp);
                  setInfo((infos) => [...infos, tmp]);
                } else {
                  const refBillebtOld = await getDoc(
                    doc(
                      db,
                      `users/${user.uid}/oldBillets`,
                      `${billet.billetID}`
                    )
                  );
                  const tmp = {
                    billet: refBillebtOld.data(),
                    event: refEvent.data(),
                    commande: element,
                  };
                  console.log("tmp : ", tmp);
                  setInfo((infos) => [...infos, tmp]);
                }
              });
            });
          }
        }
      } catch (error) {
        console.log(error);
      }
      setLoading(false)
    })();
  }, [user.uid]);

  return (
    <>
      <Container sx={{ paddingX: { xs: 2, sm: 5 }, paddingY: 10 }}>
        <Box paddingTop={5} display="flex" justifyContent="center">
          {
            !urlPhoto ? <Avatar sx={{ bgcolor: '#5E17EB', width: 100, height: 100 }}>{prenom.slice(0,1).toUpperCase()}{nom.slice(0,1).toUpperCase()}</Avatar>
            : <Avatar src={urlPhoto} sx={{ width: 100, height: 100 }}></Avatar>
          }
        </Box>

        <Typography
          sx={{
            fontSize: "30px",
            fontWeight: "600",
          }}
          align="center"
        >
          {prenom} {nom}
        </Typography>
        <Typography align="center">{mail}</Typography>
        <Menu
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: 48 * 4.5,
              width: "20ch",
            },
          }}
        >
          {options.map((option) => (
            <MenuItem
              key={option}
              selected={option === "Pyxis"}
              onClick={handleLogout}
            >
              <ListItemText>{option}</ListItemText>
              <ListItemIcon>
                <Iconify
                  icon="ph:sign-out-fill"
                  sx={{
                    color: "red",
                    width: 24,
                    height: 24,
                    alignItems: "center",
                  }}
                />
              </ListItemIcon>
            </MenuItem>
          ))}
        </Menu>

        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <Box sx={{ width: { xs: "100%", sm: "70%" } }}>
            <Box>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="A venir" {...a11yProps(0)} />
                <Tab label="Passés" {...a11yProps(1)} />
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={open ? "long-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                  >
                    <Iconify
                      icon="icon-park-outline:setting-two"
                      sx={{
                        width: 24,
                        height: 24,
                        alignItems: "center",
                      }}
                    />
                  </IconButton>
                </Box>
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              {
                loading ? <Card sx={{ marginY: 2 }}>
                <Grid padding={3} container spacing={2}>
                  <Grid item xs={12} sm={4}>
                  <Skeleton variant="rectangular" height={140} />
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Skeleton width="60%" />
                    <Skeleton width="80%" />
                    <Skeleton width="100%" />
                    <Skeleton width="40%" />
                  </Grid>
                </Grid>
              </Card>
                :
                <>
              {infos.map((info) => (
                <Ticket img={logoImg} info={info} />
              ))}
              </>
              }
              
              
            </TabPanel>
            <TabPanel value={value} index={1}>
              {infos.map((info) => (
                <Ticket img={logoImg} info={info} />
              ))}
            </TabPanel>
          </Box>
        </Box>

        <Card sx={{ bgcolor: "lightblue" }}>
          <CardContent>
            <Typography fontSize={17}>
              Conditions générales de revente La revente de billets n’est pas
              autorisée sur d’autres plateformes que Choptonticket. Tout billet
              ainsi revendu sera invalide. Pour revendre ton billet en toute
              sécurité, rendez-vous sur l’application mobile Choptonticket et
              son service de revente intégré.
            </Typography>
          </CardContent>
        </Card>
      </Container>
      <Footer />
    </>
  );
}
