import React, { createContext, useContext, useState, useEffect } from 'react';
import { setDoc, doc, collection, Timestamp, serverTimestamp } from 'firebase/firestore';
import {createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, onAuthStateChanged} from 'firebase/auth'
import { auth, db } from '../firebase.config';



const VariableContext = createContext();

export const VariableContextProvider = ({ children }) => {
    
    const [openContact, setOpenContact] = useState(false)
    const [openLogin, setOpenLogin] = useState(false)
    
    const [openReservation, setOpenReservation] = useState(false)

    

    return (
        <VariableContext.Provider value={{ openContact, setOpenContact, openLogin, setOpenLogin, openReservation, setOpenReservation }}>
            {children}
        </VariableContext.Provider>
    );
};

export const Variables = () => useContext(VariableContext);