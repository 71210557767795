import {
  Box,
  Container,
  styled,
  Typography,
  Tabs,
  Tab,
} from "@mui/material";
import React from "react";
import All from "./home/all";
import ThisWeek from "./home/thisWeek";
import Today from "./home/today";
import Concert from "./home/concert";
import Festival from "./home/festival";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Properties = () => {

  const PropertiesTextBox = styled(Box)(({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
  }));

  

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ mt: { xs: 0, sm: 5 } }}>
      <Container>
        <PropertiesTextBox>
          <Typography
          align="left"
            sx={{ color: "#000339", fontSize: "33px", fontWeight: "800" }}
          >
            ÉVÈNEMENTS EN APPROCHE
          </Typography>
        </PropertiesTextBox>
        <Box sx={{ width: "100%" }}>
          <Box>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              variant="scrollable"
              allowScrollButtonsMobile
            >
              <Tab label="Tous" {...a11yProps(0)} />
              <Tab label="Aujourd'hui" {...a11yProps(1)} />
              <Tab label="Ce week-end" {...a11yProps(2)} />
              <Tab label="Concert" {...a11yProps(3)} />
              <Tab label="Festival" {...a11yProps(4)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <All />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Today />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <ThisWeek />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <Concert />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <Festival />
          </TabPanel>
        </Box>
      </Container>
    </Box>
  );
};

export default Properties;
