import { Skeleton, Typography } from "@mui/material";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { useCallback } from "react";
import { useMemo } from "react";



export default function MapsSearch({centerC, hover, loading, eventsList}) {
  
  const events = useMemo(() => {
    if (eventsList) {
      let tmp = []
      eventsList.forEach(element => {
        tmp.push(element.infos.data().localisation)
      });
      return tmp
    } else {
      return []
    }
  }, [eventsList]);
  const center = useMemo(() => {
    if (centerC) {
      return centerC
    } else if(hover){
      return hover
    } else {
      return { lat: 5.3599517, lng: -4.0082563 }
    }
  }, [centerC, hover]);
  if (!loading) return <Skeleton variant="rounded" sx={{height: "100vh", width: '42%'}} />;
  return (
    <GoogleMap
      zoom={13}
      center={center}
      mapContainerClassName="map-container"
      mapContainerStyle={{height: "100vh", width: '42%'}}
    >
      {
        events.length !== 0 && events.map((event) => (<Marker  position={event} label={events.length}/>))
      }
    </GoogleMap>
  );
}
