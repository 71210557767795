import { Document, Image, Page, View, Text } from "@react-pdf/renderer";
import QRCodeComponent from "./Qrcode";


const styles = {
  page: {
    backgroundColor: "#fff",
    fontFamily: "Helvetica",
    lineHeight: 1.5,
    flexDirection: "column",
  },
  logo: {
    width: 50,
    height: 50,
    marginLeft: "auto",
    marginRight: "auto",
  },
  imageContainer: {
    backgroundColor: "#5E17EB",
    width: "100%",
    height: "40%",
  },
  image: {
    backgroundColor: "#5E17EB",
    width: "100%",
    height: "100%",
  },
  // eventpart
  eventBg: {
    backgroundColor: "#F2ECFD",
  },
  eventPart: {
    flexDirection: "row",
    marginTop: 15,
    marginBottom: 15,
    marginHorizontal: 20,
  },
  eventPart1: {
    width: "50%",
    textAlign: "left",
  },
  eventPart2: {
    width: "50%",
    textAlign: "left",
  },
  ticketPart: {
    flexDirection: "row",
    marginTop: 15,
    marginBottom: 15,
    marginHorizontal: 20,
  },
  ticketPart1: {
    width: "50%",
    textAlign: "left",
  },
  ticketPart2: {
    width: "50%",
    textAlign: "center",
  },
  textHead: {
    fontSize: 12,
    fontWeight: "bold",
  },
  textEventname: {
    fontSize: 15,
    fontWeight: "heavy",
  },
  textSecondary: {
    fontSize: 10,
    fontWeight: "bold",
    color: '#bababa'
  },
  textImportant: {
    fontSize: 13,
    fontWeight: 900,
  },
  text: {
    fontSize: 10,
  },
  textBold: {
    fontSize: 10,
    fontWeight: "bold",
  },
  textDetails: {
    fontSize: 7,
  },
  qrCodeContainer: {
    
    display:'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 20,
  
  },
  qrCode: {
    height: 130,
    width: 130,
  },
  detailContain: {
    marginTop: 15,
    padding: 5,
    backgroundColor: "#d4f0ff",
    width: "100%",
  },
};

const PdfTicket = ({ info, qr }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.imageContainer}>
          <Image style={styles.image} src={info.event.idVisuel} />
        </View>
        <View style={styles.eventBg}>
          <View style={styles.eventPart}>
            <View style={styles.eventPart1}>
              <View>
                <Text style={{ fontWeight: 900, fontSize: 15 }}>
                  {" "}
                  {info.event.nomEvent}
                </Text>
                <Text break style={styles.textSecondary}>
                  {" "}
                  Par Organisateur
                </Text>
              </View>
            </View>
            <View style={styles.eventPart2}>
              <Text style={styles.textSecondary}>Date de l'evenement</Text>
              <Text break style={styles.text}>
                {" "}
                {info.event.lieu_event} {info.event.city}, {info.event.country}
              </Text>
              <Text style={styles.textSecondary}> Adresse</Text>
              <Text break style={styles.text}>
                {" "}
                {info.event.lieu_event} {info.event.city}, {info.event.country}
              </Text>
            </View>
          </View>
        </View>
        <View>
          <View style={styles.ticketPart}>
            <View style={styles.ticketPart1}>
              <Text style={styles.textHead}> {info.billet.nom}</Text>
              <View>
                <Text style={styles.textBold}>
                  {" "}
                  Billet : {info.billet.numero} - Prix : {info.billet.price} CFA
                </Text>
                <Text break style={styles.textBold}>
                  {" "}
                  Commande N° {info.billet.refCommande}{" "}
                </Text>
                <Text break style={styles.text}>
                  {" "}
                  Client : {info.commande.nom} {info.commande.email}{" "}
                </Text>
              </View>
            </View>
            <View style={styles.ticketPart2}>
              <View style={styles.qrCodeContainer}>
                <Image
                  style={styles.qrCode}
                  src={qr}/>
                <Text break style={styles.text}>
                  {" "}
                  {info.billet.numero}
                </Text>
              </View>

              <View  style={styles.detailContain}>
                <Text style={styles.text}>
                  {" "}
                  Modalite et condition de revent
                </Text>
                <Text break style={styles.textDetails}>
                  Tout billet revendu sur une plateforme de revente autre que
                  l’application ChoptonTicket n’est pas sécurisé et ne garantit
                  pas l’entrée à l’évènement.
                </Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default PdfTicket;
