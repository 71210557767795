import {
  Backdrop,
  Box,
  Button,
  Card,
  Divider,
  Fade,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Modal,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useLoadScript } from "@react-google-maps/api";
import {
  collection,
  doc,
  endBefore,
  getCountFromServer,
  getDoc,
  getDocs,
  limit,
  limitToLast,
  or,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useResponsive from "../Components/hooks/useResponsive";
import Iconify from "../Components/Iconify";
import ResearchItem from "../Components/researchItem";
import { db } from "../firebase.config";
import ResearchItemLoading from "../Components/Research/ResearchItemLoading";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import Paginate from "../Components/paginate";
import MapsSearch from "../Components/map/MapsClustering";



const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  p: 2,
  overflowY: "auto",
  height: "100%",
  width: "100%",
};

const SearchPage = () => {
  const smUp = useResponsive("up", "sm");

  const mdUp = useResponsive("up", "md");
  const { city, filtres, name } = useParams();
  const [types, setTypes] = React.useState([
    {
      id: "concert",
      nom: "Concert",
      icon: "material-symbols:music-note-rounded",
    },
    { id: "atelier", nom: "Atelier", icon: "grommet-icons:workshop" },
    { id: "soirée gala", nom: "Soirée clubbing", icon: "" },
    { id: "festival", nom: "Festival", icon: "" },
  ])
  const [cat, setCat] = React.useState(["Concert", "Gala", "Festival", "Atelier"])
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [events, setEvents] = React.useState([]);
  const [orgs, setOrgs] = React.useState([]);
  const [filtreValue, setFiltreValue] = React.useState([]);
  const [dateFilter, setDateFilter] = React.useState("");
  const [prixFilter, setPrixFilter] = React.useState("");
  const [catFilter, setCatFilter] = React.useState("");
  const [cityField, setCityField] = React.useState("");
  const [eventField, setEventField] = React.useState("");
  const [hoverE, setHover] = React.useState();

  //pagination
  const [queryList, setQuery] = React.useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [totalCount, setTotalCount] = React.useState();
  const [page, setPage] = React.useState(1);
  const [totalPage, setTotalPage] = React.useState(1);
 
  const getMoreType = async () => {
    
    const q = query(
      collection(db, "types"),
    );
    const typeSnap = await getDocs(q);
    console.log(typeSnap)
    setTypes([])
    typeSnap.forEach(element => {
      setTypes((types) => [...types, {'id': element.id, 'nom': element.data()['nom']}]);
      setCat((cats) => [...cats, element.data()['nom']]);
    });
  }

  const showNext = ( item) => {
    console.log("event size : ",events.length);
    if (page === totalPage) {
      console.log("Thats all we have for now !");
    } else {
      const fetchNextData = async () => {
        setLoading(true)
        setOrgs([]);
        setEvents([]);
        const today = new Date();
        const q = query(
          collection(db, "evenement"),
          where("status", "==", 1),
          where(
            "dateDebut",
            ">=",
            new Date(today.getFullYear(), today.getMonth(), today.getDate())
          ),
          ...queryList,
          orderBy("dateDebut", "asc"),
          limit(itemsPerPage),
          startAfter(item.dateDebut)
        );
        const q1 = query(
          collection(db, "evenement"),
          where("status", "==", 1),
          where(
            "dateDebut",
            ">=",
            new Date(today.getFullYear(), today.getMonth(), today.getDate())
          ),
          ...queryList,
          orderBy("dateDebut", "asc"),
        );
        const querySnapshot = await getDocs(q);
        // get total account
        const total = await getCountFromServer(query(q1));
        setTotalPage(Math.ceil(total.data().count/ itemsPerPage));

        setTotalItems(total.data().count);
        querySnapshot.forEach(async (d) => {
          setPage(page + 1);
          // get event organisateur
          const querySnapshot1 = await getDoc(
            doc(db, "organisations", d.data().orgId)
          );
          setEvents((events) => [
            ...events,
            { infos: d, org: querySnapshot1.data().nomOrg },
          ]);
        });
        setLoading(false)
      };

      fetchNextData();
    }
  };

  const showPrevious = (item ) => {
    if(page === 1){
      console.log('impossible')
    }else{
      const fetchPreviousData = async () => {
        setLoading(true)
        setEvents([]);
        setOrgs([]);
        const today = new Date();
        const q = query(
          collection(db, "evenement"),
          where("status", "==", 1),
          where(
            "dateDebut",
            ">=",
            new Date(today.getFullYear(), today.getMonth(), today.getDate())
          ),
          ...queryList,
          orderBy("dateDebut", "asc"),
          endBefore(item.dateDebut),
          limitToLast(itemsPerPage)
        );
        const q1 = query(
          collection(db, "evenement"),
          where("status", "==", 1),
          where(
            "dateDebut",
            ">=",
            new Date(today.getFullYear(), today.getMonth(), today.getDate())
          ),
          ...queryList,
          orderBy("dateDebut", "asc"),
        );
        const querySnapshot = await getDocs(q);
        // get total account
        const total = await getCountFromServer(query(q1));
        setTotalPage(Math.ceil(total.data().count / itemsPerPage) );
  
        setTotalItems(total.data().count);
        querySnapshot.forEach(async (d) => {
          setPage(page - 1);
          // get event organisateur
          const querySnapshot1 = await getDoc(
            doc(db, "organisations", d.data().orgId)
          );
          setEvents((events) => [
            ...events,
            { infos: d, org: querySnapshot1.data().nomOrg },
          ]);
        });
        setLoading(false)
      };
      fetchPreviousData();
    }
  };

  React.useEffect(() => {
    (async () => {
      try {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        setPage(1)
        setLoading(true);
        setEvents([]);
        setOrgs([]);
        setQuery([])
        setCityField("");
        console.log(city);
        console.log(filtres);
        console.log(name);

        //les dates
        const today = new Date();
        const ttmp = new Date(today.getTime() + 24 * 60 * 60 * 1000);
        const tomorrow = new Date(
          ttmp.getFullYear(),
          ttmp.getMonth(),
          ttmp.getDate()
        );

        console.log("demain : ", tomorrow);
        const startOfWeekendDate = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate()
        );
        startOfWeekendDate.setDate(
          startOfWeekendDate.getDate() + (5 - startOfWeekendDate.getDay())
        );

        const endOfWeekendDate = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate()
        );

        endOfWeekendDate.setDate(
          endOfWeekendDate.getDate() + (8 - endOfWeekendDate.getDay())
        );
        console.log("debut week : ", startOfWeekendDate);
        console.log("fin week : ", endOfWeekendDate);

        
        if (city === "all--city" && filtres === "all--event") {
          const q = query(
            collection(db, "evenement"),
            where("status", "==", 1),
            where(
              "dateDebut",
              ">=",
              new Date(today.getFullYear(), today.getMonth(), today.getDate())
            ),
            orderBy("dateDebut", "asc"),
            limit(itemsPerPage)
          );
          const q1 = query(
            collection(db, "evenement"),
            where("status", "==", 1),
            where(
              "dateDebut",
              ">=",
              new Date(today.getFullYear(), today.getMonth(), today.getDate())
            ),
            orderBy("dateDebut", "asc"),
          );
          const querySnapshot = await getDocs(q);
          // get total account
          const total = await getCountFromServer(query(q1));
          console.log(Math.ceil(total.data().count) / 5);

          setTotalItems(total.data().count);
          setTotalPage(Math.ceil(total.data().count / itemsPerPage) );
          querySnapshot.forEach(async (d) => {
            // get event organisateur
            const querySnapshot1 = await getDoc(
              doc(db, "organisations", d.data().orgId)
            );
            setEvents((events) => [
              ...events,
              { infos: d, org: querySnapshot1.data().nomOrg },
            ]);
          });
        } else {
          const AllFilter = filtres.split("--");
          const cityFilter = city.split("--");
          console.log(AllFilter);

          const priceTmp = ["paid", "free"];
          const catTmp = cat;
          const dateTmp = ["today", "tomorrow", "this-weekend"];
          let dateF = "";
          let catF = "";
          let prixF = "";
          let queryContains = [];
          if (name) {
            console.log("nom");
            setEventField(name);
          }
          // if la ville est indiqué

          if (city !== "all--city") {
            setCityField(cityFilter[1]);
            queryContains.push(where("city", "==", cityFilter[1]));
          }
          priceTmp.forEach((element) => {
            //const exist = AllFilter.findIndex((id) => id === element);

            if (AllFilter.findIndex((id) => id === element) !== -1) {
              setPrixFilter(
                AllFilter[AllFilter.findIndex((id) => id === element)]
              );
              prixF = AllFilter[AllFilter.findIndex((id) => id === element)];
            }
          });
          catTmp.forEach((element) => {
            if (AllFilter.findIndex((id) => id === element) !== -1) {
              setCatFilter(
                AllFilter[AllFilter.findIndex((id) => id === element)]
              );
              queryContains.push(
                where(
                  "type",
                  "==",
                  AllFilter[AllFilter.findIndex((id) => id === element)]
                )
              );
            }
          });
          dateTmp.forEach((element) => {
            if (AllFilter.findIndex((id) => id === element) !== -1) {
              setDateFilter(
                AllFilter[AllFilter.findIndex((id) => id === element)]
              );
              if (element === "today") {
                queryContains.push(
                  where(
                    "dateDebut",
                    ">=",
                    new Date(
                      today.getFullYear(),
                      today.getMonth(),
                      today.getDate()
                    )
                  )
                );
                queryContains.push(
                  where(
                    "dateDebut",
                    "<",
                    new Date(today.getTime() + 24 * 60 * 60 * 1000)
                  )
                );
              } else if (element === "tomorrow") {
                queryContains.push(where("dateDebut", ">=", tomorrow));
                queryContains.push(
                  where(
                    "dateDebut",
                    "<",
                    new Date(tomorrow.getTime() + 24 * 60 * 60 * 1000)
                  )
                );
              } else if (element === "this-weekend") {
                queryContains.push(
                  where("dateDebut", ">=", startOfWeekendDate)
                );
                queryContains.push(where("dateDebut", "<=", endOfWeekendDate));
              }
            }
          });
          // get total account
          setQuery(queryContains);
          const total = await getCountFromServer(
            query(
              collection(db, "evenement"),
              where("status", "==", 1),
              where(
                "dateDebut",
                ">=",
                new Date(today.getFullYear(), today.getMonth(), today.getDate())
              ),
              ...queryContains,
              orderBy("dateDebut", "asc")
            )
          );
          console.log(Math.ceil(total.data().count) / 15);
          setTotalCount(Math.ceil(total.data().count) / 15);
          setTotalItems(total.data().count);

          const q = query(
            collection(db, "evenement"),
            where("status", "==", 1),
            where(
              "dateDebut",
              ">=",
              new Date(today.getFullYear(), today.getMonth(), today.getDate())
            ),
            ...queryContains,
            orderBy("dateDebut", "asc"),
            limit(itemsPerPage)
          );
          setTotalPage(Math.ceil(total.data().count / itemsPerPage) );
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach(async (d) => {
            // get event organisateur
            const querySnapshot1 = await getDoc(
              doc(db, "organisations", d.data().orgId)
            );
            setEvents((events) => [
              ...events,
              { infos: d, org: querySnapshot1.data().nomOrg },
            ]);
            // setLoading(false);
          });
        }

        //setLoading(false);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
      console.log("size 1 : ", events);
      console.log("size 2 : ", orgs);
    })();
  }, [filtres, city, currentPage]);

  const changeDate = (e) => {
    setDateFilter(e.target.value);
    navigate(
      `/fr/${city}/${prixFilter === "" ? "" : `${prixFilter}--`}${
        catFilter === "" ? "" : `${catFilter}--`
      }events--${e.target.value}`
    );
  };

  const changePrix = (e) => {
    setPrixFilter(e.target.value);
    navigate(
      `/fr/${city}/${e.target.value}--${
        catFilter === "" ? "" : `${catFilter}--`
      }events${dateFilter === "" ? "" : `--${dateFilter}`}`
    );
  };
  const changeCat = (e) => {
    setCatFilter(e.target.value);
    navigate(
      `/fr/${city}/${prixFilter === "" ? "" : `${prixFilter}--`}${
        e.target.value
      }--events${dateFilter === "" ? "" : `--${dateFilter}`}`
    );
  };
  const removeCatFilter = (e) => {
    setCatFilter("");
    if (dateFilter === "" && prixFilter === "") {
      navigate(`/fr/${city}/all--event`);
    } else {
      navigate(
        `/fr/${city}/${prixFilter === "" ? "" : `${prixFilter}--`}events${
          dateFilter === "" ? "" : `--${dateFilter}`
        }`
      );
    }
  };
  const removePrixFilter = (e) => {
    setPrixFilter("");
    if (dateFilter === "" && catFilter === "") {
      navigate(`/fr/${city}/all--event`);
    } else {
      navigate(
        `/fr/${city}/${catFilter === "" ? "" : `${catFilter}--`}events${
          dateFilter === "" ? "" : `--${dateFilter}`
        }`
      );
    }
  };
  const removeDateFilter = (e) => {
    setDateFilter("");
    if (prixFilter === "" && catFilter === "") {
      navigate(`/fr/${city}/all--event`);
    } else {
      navigate(
        `/fr/${city}/${prixFilter === "" ? "" : `${prixFilter}--`}${
          catFilter === "" ? "" : `${catFilter}--`
        }events`
      );
    }
  };
  const [center, setCenter] = React.useState();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  // google place auto
  const libraries = ["places"];
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_MAP_API_KEY,
    libraries,
  });
  const searchOptions = {
    componentRestrictions: { country: "CIV" },
    fields: ["address_components", "geometry", "icon", "name"],
    types: ["locality"],
  };
  const handleSelect = async (address, placeId, suggestion) => {
    console.log(suggestion.description);
    console.log(address);
    console.log(placeId);
    const tmp = suggestion.description.split(",");
    //setLieu(tmp[0]);
    //setVille(tmp[tmp.length - 2]);
    //setpays(tmp[tmp.length - 1]);
    //setAddress(suggestion.description);
    setCityField(tmp[0]);
    const results = await geocodeByAddress(address);
    const ll = await getLatLng(results[0]);
    console.log(results);
    console.log(ll);
    setCenter(ll);
    if (filtres === "all--event") {
      navigate(`/fr/${tmp[1]}--${tmp[0]}/all--event`);
    } else {
      navigate(
        `/fr/${tmp[1]}--${tmp[0]}/${
          prixFilter === "" ? "" : `${prixFilter}--`
        }${catFilter === "" ? "" : `${catFilter}--`}events${
          dateFilter === "" ? "" : `--${dateFilter}`
        }`
      );
    }
  };

  return (
    <Box sx={{ paddingY: { xs: 10, sm: 10 } }}>
      <Grid paddingBottom={5} container spacing={2}>
        <Grid item xs={12} md={7}>
          <Grid container paddingX={2}>
            {/*** Textfield recherche */}
            <Grid item xs={12}>
              <Stack direction="row" spacing={2}>
                <Stack direction="column" sx={{ width: "100%" }}>
                  <TextField
                    variant="outlined"
                    placeholder="Recherche vos events"
                    sx={{
                      "& label.Mui-focused": {
                        color: "white",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "white",
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "white",
                        },
                        "&:hover fieldset": {
                          borderColor: "white",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "white",
                        },
                      },
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Iconify
                            icon="material-symbols:search-rounded"
                            sx={{ width: 30, height: 30 }}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                  {isLoaded && (
                    <PlacesAutocomplete
                      value={cityField}
                      onChange={setCityField}
                      onSelect={handleSelect}
                      searchOptions={searchOptions}
                      googleCallbackName=""
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div>
                          <TextField
                            fullWidth
                            {...getInputProps({
                              placeholder: "Choisit une location",
                            })}
                            variant="outlined"
                            sx={{
                              "& label.Mui-focused": {
                                color: "white",
                              },
                              "& .MuiInput-underline:after": {
                                borderBottomColor: "white",
                              },
                              "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                  borderColor: "white",
                                },
                                "&:hover fieldset": {
                                  borderColor: "white",
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: "white",
                                },
                              },
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Iconify
                                    icon="material-symbols:location-on-outline"
                                    sx={{ width: 30, height: 30 }}
                                  />
                                </InputAdornment>
                              ),
                              endAdornment: (
                                <InputAdornment position="end">
                                  {cityField !== "" && (
                                    <IconButton
                                      onClick={() => {
                                        if (filtres === "all--event") {
                                          navigate(`/fr/all--city/all--event`);
                                        } else {
                                          navigate(
                                            `/fr/all--city/${
                                              prixFilter === ""
                                                ? ""
                                                : `${prixFilter}--`
                                            }${
                                              catFilter === ""
                                                ? ""
                                                : `${catFilter}--`
                                            }events${
                                              dateFilter === ""
                                                ? ""
                                                : `--${dateFilter}`
                                            }`
                                          );
                                        }
                                      }}
                                    >
                                      <Iconify
                                        icon="material-symbols:close-rounded"
                                        sx={{ width: 24, height: 24 }}
                                      />
                                    </IconButton>
                                  )}
                                </InputAdornment>
                              ),
                            }}
                          />

                          <Card>
                            {loading && <div>Loading...</div>}
                            {suggestions.map((suggestion) => {
                              const className = suggestion.active
                                ? "suggestion-item--active"
                                : "suggestion-item";
                              // inline style for demonstration purpose
                              const style = suggestion.active
                                ? {
                                    backgroundColor: "#fafafa",
                                    cursor: "pointer",
                                    padding: 2,
                                  }
                                : {
                                    backgroundColor: "#ffffff",
                                    cursor: "pointer",
                                    padding: 2,
                                  };
                              return (
                                <Box
                                  paddingX={2}
                                  key={suggestion.placeId}
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}
                                >
                                  <Typography fontSize={16}>
                                    {suggestion.description}
                                  </Typography>
                                </Box>
                              );
                            })}
                          </Card>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  )}
                </Stack>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  {!smUp ? (
                    <Box sx={{ borderRadius: "50px", bgcolor: "#f5f5f5" }}>
                      <IconButton aria-label="search" size="large">
                        <Iconify
                          icon="material-symbols:search-rounded"
                          sx={{ width: 24, height: 24 }}
                        />
                      </IconButton>
                    </Box>
                  ) : (
                    <Button
                      size="large"
                      sx={{
                        textTransform: "initial",
                        border: "2px solid transparent",
                        color: "white",
                        backgroundColor: "#5E17EB",
                        borderRadius: "7px",
                        "&:hover": {
                          backgroundColor: "white",
                          color: "#5E17EB",
                          borderColor: "#5E17EB",
                        },
                      }}
                      variant="contained"
                    >
                      Recherche
                    </Button>
                  )}
                </Box>
              </Stack>
              <Divider />
            </Grid>
            {/** event + filtres */}
            <Grid item xs={12} paddingY={3}>
              <Grid container>
                {/** filtres */}
                {smUp && mdUp && (
                  <Grid item xs={3}>
                    <Typography variant="h5" fontWeight={600}>
                      Filtres
                    </Typography>
                    <Stack>
                      <FormControl
                        sx={{ m: 3 }}
                        component="fieldset"
                        variant="standard"
                      >
                        <FormLabel component="legend">Date</FormLabel>
                        <RadioGroup
                          value={dateFilter}
                          onChange={(e) => changeDate(e)}
                        >
                          <FormControlLabel
                            value="today"
                            control={<Radio />}
                            label={
                              <Typography fontSize={14}>Aujourd'hui</Typography>
                            }
                          />
                          <FormControlLabel
                            value="tomorrow"
                            control={<Radio />}
                            label={
                              <Typography fontSize={14}>Demain</Typography>
                            }
                          />
                          <FormControlLabel
                            value="this-weekend"
                            control={<Radio />}
                            label={
                              <Typography fontSize={14}>Ce Weekend</Typography>
                            }
                          />
                        </RadioGroup>
                      </FormControl>
                    </Stack>
                    {/** price filter */}
                    <Stack>
                      <FormControl
                        sx={{ m: 3 }}
                        component="fieldset"
                        variant="standard"
                      >
                        <FormLabel component="legend">Prix</FormLabel>
                        <RadioGroup
                          value={prixFilter}
                          onChange={(e) => changePrix(e)}
                        >
                          <FormControlLabel
                            value="free"
                            control={<Radio />}
                            label={
                              <Typography fontSize={14}>Gratuit</Typography>
                            }
                          />
                          <FormControlLabel
                            value="paid"
                            control={<Radio />}
                            label={
                              <Typography fontSize={14}>Payant</Typography>
                            }
                          />
                        </RadioGroup>
                      </FormControl>
                    </Stack>
                    {/** price filtre */}
                    <Stack>
                      <FormControl
                        sx={{ m: 3 }}
                        component="fieldset"
                        variant="standard"
                      >
                        <FormLabel component="legend">Categories</FormLabel>
                        <RadioGroup
                          value={catFilter}
                          onChange={(e) => changeCat(e)}
                        >
                          {types.map((type) => (
                            <FormControlLabel
                              value={type.nom}
                              control={<Radio />}
                              label={
                                <Typography fontSize={14}>
                                  {type.nom}
                                </Typography>
                              }
                            />
                          ))}
                        </RadioGroup>
                        <Link fontSize={14} onClick={() => { getMoreType ()}}>afficher plus</Link>
                      </FormControl>
                      
                    </Stack>
                  </Grid>
                )}

                <Grid paddingY={{ xs: 0, sm: 2 }} item xs={12} md={9}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sx={{ position: "sticky" }}>
                      <Stack
                        direction="row"
                        paddingX={{ xs: 1, sm: 3 }}
                        sx={{ overflowX: "auto", width: "100%" }}
                      >
                        {!smUp && !mdUp && (
                          <Grid item xs={5}>
                            {dateFilter !== "" ||
                            catFilter !== "" ||
                            prixFilter !== "" ? (
                              <Box
                                sx={{
                                  color: "white",
                                  border: " 1px solid #f5f5f5",
                                  bgcolor: "#5E17EB",
                                  borderRadius: "20px",
                                  marginX: 1,
                                  "&:hover": {
                                    cursor: "pointer",
                                    borderColor: "#5E17EB",
                                  },
                                }}
                              >
                                <Stack
                                  onClick={() => {
                                    handleOpen();
                                  }}
                                  direction="row"
                                  display="flex"
                                  alignItems="center"
                                  spacing={1}
                                  paddingX={2}
                                  paddingY={1}
                                >
                                  <Iconify
                                    icon="system-uicons:filtering"
                                    sx={{ width: 24, height: 24 }}
                                  />
                                  <Typography variant="body1">
                                    Fitres
                                  </Typography>
                                </Stack>
                              </Box>
                            ) : (
                              <Box
                                sx={{
                                  border: " 1px solid #f5f5f5",
                                  bgcolor: "#f5f5f5",
                                  borderRadius: "20px",
                                  marginX: 1,
                                  "&:hover": {
                                    cursor: "pointer",
                                    borderColor: "#5E17EB",
                                  },
                                }}
                              >
                                <Stack
                                  onClick={() => {
                                    handleOpen();
                                  }}
                                  direction="row"
                                  display="flex"
                                  alignItems="center"
                                  spacing={1}
                                  paddingX={2}
                                  paddingY={1}
                                >
                                  <Iconify
                                    icon="system-uicons:filtering"
                                    sx={{ width: 24, height: 24 }}
                                  />
                                  <Typography variant="body1">
                                    Fitres
                                  </Typography>
                                </Stack>
                              </Box>
                            )}
                          </Grid>
                        )}
                        {/** Filtre Modal */}
                        <Modal
                          aria-labelledby="transition-modal-title"
                          aria-describedby="transition-modal-description"
                          open={open}
                          onClose={handleClose}
                          closeAfterTransition
                          slots={{ backdrop: Backdrop }}
                          slotProps={{
                            backdrop: {
                              timeout: 500,
                            },
                          }}
                        >
                          <Fade in={open}>
                            <Box sx={style}>
                              <Grid item xs={12} sx={{overflowX: "auto", overflowY: "auto"}}>
                                <Card
                                  elevation={0}
                                  sx={{
                                    position: "fixed",
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    paddingX: 2,
                                    paddingY: 2,
                                    
                                  }}
                                >
                                  <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                  >
                                    <Typography variant="h5" fontWeight={600}>
                                      Filtres
                                    </Typography>
                                    <IconButton
                                      aria-label="search"
                                      size="large"
                                      onClick={handleClose}
                                    >
                                      <Iconify
                                        icon="ri:arrow-drop-down-line"
                                        sx={{ width: 24, height: 24 }}
                                      />
                                    </IconButton>
                                  </Stack>
                                </Card>
                                <Stack paddingTop={5}>
                                  <FormControl
                                    sx={{ mt: 3 }}
                                    component="fieldset"
                                    variant="standard"
                                  >
                                    <FormLabel component="legend">
                                      Date
                                    </FormLabel>
                                    <RadioGroup
                                      value={dateFilter}
                                      onChange={(e) => changeDate(e)}
                                    >
                                      <FormControlLabel
                                        value="today"
                                        control={<Radio />}
                                        label={
                                          <Typography fontSize={14}>
                                            Aujourd'hui
                                          </Typography>
                                        }
                                      />
                                      <FormControlLabel
                                        value="tomorrow"
                                        control={<Radio />}
                                        label={
                                          <Typography fontSize={14}>
                                            Demain
                                          </Typography>
                                        }
                                      />
                                      <FormControlLabel
                                        value="this-weekend"
                                        control={<Radio />}
                                        label={
                                          <Typography fontSize={14}>
                                            Ce Weekend
                                          </Typography>
                                        }
                                      />
                                    </RadioGroup>
                                  </FormControl>
                                </Stack>
                                {/** price filter */}
                                <Stack>
                                  <FormControl
                                    sx={{ mt: 3 }}
                                    component="fieldset"
                                    variant="standard"
                                  >
                                    <FormLabel component="legend">
                                      Prix
                                    </FormLabel>
                                    <RadioGroup
                                      value={prixFilter}
                                      onChange={(e) => changePrix(e)}
                                    >
                                      <FormControlLabel
                                        value="free"
                                        control={<Radio />}
                                        label={
                                          <Typography fontSize={14}>
                                            Gratuit
                                          </Typography>
                                        }
                                      />
                                      <FormControlLabel
                                        value="paid"
                                        control={<Radio />}
                                        label={
                                          <Typography fontSize={14}>
                                            Payant
                                          </Typography>
                                        }
                                      />
                                    </RadioGroup>
                                  </FormControl>
                                </Stack>
                                {/** price filtre */}
                                <Stack>
                                  <FormControl
                                    sx={{ mt: 3 }}
                                    component="fieldset"
                                    variant="standard"
                                  >
                                    <FormLabel component="legend">
                                      Categories
                                    </FormLabel>
                                    <RadioGroup
                                      value={catFilter}
                                      onChange={(e) => changeCat(e)}
                                    >
                                      {types.map((type) => (
                                        <FormControlLabel
                                          value={type.id}
                                          control={<Radio />}
                                          label={
                                            <Typography fontSize={14}>
                                              {type.nom}
                                            </Typography>
                                          }
                                        />
                                      ))}
                                    </RadioGroup>
                                  </FormControl>
                                  <Typography>plus</Typography>
                                </Stack>
                              </Grid>
                              <Card
                                elevation={0}
                                sx={{
                                  position: "fixed",
                                  bottom: 0,
                                  left: 0,
                                  right: 0,
                                  paddingX: 3,
                                  paddingY: 2,
                                }}
                              >
                                <Button
                                  size="large"
                                  fullWidth
                                  sx={{
                                    textTransform: "initial",
                                    border: "2px solid transparent",
                                    color: "white",
                                    backgroundColor: "#5E17EB",
                                    borderRadius: "7px",
                                    "&:hover": {
                                      backgroundColor: "white",
                                      color: "#5E17EB",
                                      borderColor: "#5E17EB",
                                    },
                                  }}
                                  variant="contained"
                                  onClick={handleClose}
                                >
                                  Afficher les resultats
                                </Button>
                              </Card>
                            </Box>
                          </Fade>
                        </Modal>
                        {dateFilter !== "" && (
                          <Box
                            sx={{
                              border: " 1px solid #f5f5f5",
                              bgcolor: "#f5f5f5",
                              borderRadius: "20px",
                              marginX: 1,
                              "&:hover": {
                                cursor: "pointer",
                                borderColor: "#5E17EB",
                              },
                            }}
                          >
                            <Stack
                              direction="row"
                              display="flex"
                              alignItems="center"
                              spacing={1}
                              paddingX={2}
                              paddingY={1}
                            >
                              <Typography
                                noWrap
                                sx={{ textOverflow: "ellipsis", width: "100%" }}
                                variant="caption"
                              >
                                {dateFilter === "today"
                                  ? "Aujourd'hui"
                                  : dateFilter === "tomorrow"
                                  ? "Demain"
                                  : dateFilter === "this-weekend"
                                  ? "Ce weekend"
                                  : null}
                              </Typography>
                              <Iconify
                                icon="material-symbols:close-rounded"
                                onClick={() => removeDateFilter()}
                              />
                            </Stack>
                          </Box>
                        )}
                        {prixFilter !== "" && (
                          <Box
                            sx={{
                              border: " 1px solid #f5f5f5",
                              bgcolor: "#f5f5f5",
                              borderRadius: "20px",
                              marginX: 1,
                              "&:hover": {
                                cursor: "pointer",
                                borderColor: "#5E17EB",
                              },
                            }}
                          >
                            <Stack
                              direction="row"
                              display="flex"
                              alignItems="center"
                              spacing={1}
                              paddingX={2}
                              paddingY={1}
                            >
                              <Typography
                                noWrap
                                sx={{ textOverflow: "ellipsis", width: "100%" }}
                                variant="caption"
                              >
                                {prixFilter === "paid"
                                  ? "Payant"
                                  : prixFilter === "free"
                                  ? "Gratuit"
                                  : null}
                              </Typography>
                              <Iconify
                                icon="material-symbols:close-rounded"
                                onClick={() => removePrixFilter()}
                              />
                            </Stack>
                          </Box>
                        )}
                        {catFilter !== "" && (
                          <Box
                            sx={{
                              border: " 1px solid #f5f5f5",
                              bgcolor: "#f5f5f5",
                              borderRadius: "20px",
                              marginX: 1,
                              "&:hover": {
                                cursor: "pointer",
                                borderColor: "#5E17EB",
                              },
                            }}
                          >
                            <Stack
                              direction="row"
                              display="flex"
                              alignItems="center"
                              spacing={1}
                              paddingX={2}
                              paddingY={1}
                            >
                              <Typography
                                noWrap
                                sx={{ textOverflow: "ellipsis", width: "100%" }}
                                variant="caption"
                              >
                                {catFilter}
                              </Typography>
                              <Iconify
                                icon="material-symbols:close-rounded"
                                onClick={() => removeCatFilter()}
                              />
                            </Stack>
                          </Box>
                        )}
                        {(prixFilter !== "" ||
                          dateFilter !== "" ||
                          catFilter !== "") && (
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Typography variant="caption">
                              Tout Annuler
                            </Typography>
                          </Box>
                        )}
                      </Stack>
                    </Grid>

                    {/**affichage de la liste des event */}
                    {loading ? (
                      <ResearchItemLoading />
                    ) : (
                      <>
                        {events.map((property, index) => (
                          <Grid item xs={12} sx={{ height: `100%` }}>
                            <Box
                            onClick={() => {
                              navigate(`/fr/event/${property.infos.id}`);
                            }}
                              onMouseEnter={() => {
                                setHover(property.infos.data().localisation);
                              }}
                            >
                              <ResearchItem
                                fin={property.infos.data().dateFin}
                                seanceActive={
                                  property.infos.data().seanceActive
                                }
                                key={property.infos.id}
                                img={property.infos.data().idVisuel}
                                name={property.infos.data().nomEvent}
                                lieu={property.infos.data().lieu_event}
                                city={property.infos.data().city}
                                type={property.infos.data().type}
                                debut={property.infos.data().dateDebut}
                                organisateur={property.org}
                                eventID={property.infos.id}
                                sx={{ height: `100%` }}
                              />
                            </Box>
                          </Grid>
                        ))}
                      </>
                    )}
                  </Grid>
                  {!loading && events.length === 0 && (
                    <Grid paddingY={5} item xs={12} md={9}>
                      <Stack
                        spacing={3}
                        direction="row"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Typography variant="h7" fontWeight={600}>
                          Rien ne correspond à votre recherche, mais vous
                          pourriez être intéressé d'autres événements.
                        </Typography>
                      </Stack>
                    </Grid>
                  )}

                  {!loading && totalItems > itemsPerPage && (
                    <Grid paddingY={5} item xs={12} md={9}>
                      <Stack
                        spacing={3}
                        direction="row"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        {
                          page === 1 ? 
                          <Iconify
                          icon="ep:arrow-left"
                          sx={{
                            color:'transparent',
                            height: 22,
                            width: 22,
                            "&:hover": {
                              cursor: "pointer",
                            },
                          }}
                        /> :
                        <Iconify
                        icon="ep:arrow-left"
                          sx={{
                            height: 22,
                            width: 22,
                            "&:hover": {
                              cursor: "pointer",
                            },
                          }}
                          onClick={() => {showPrevious(events[0].infos.data())}}
                        />
                        }
                        
                        <Stack spacing={1} direction="row">
                          <Typography color="#5E17EB">{page}</Typography>
                          <Typography>sur</Typography>
                          <Typography>{totalPage}</Typography>
                        </Stack>
                        {
                          page === totalPage ? 
                          <Iconify
                          icon="ep:arrow-right"
                          sx={{
                            color:'transparent',
                            height: 22,
                            width: 22,
                            "&:hover": {
                              cursor: "pointer",
                            },
                          }}
                        /> :
                        <Iconify
                          icon="ep:arrow-right"
                          sx={{
                            height: 22,
                            width: 22,
                            "&:hover": {
                              cursor: "pointer",
                            },
                          }}
                          onClick={() => {showNext(events[events.length - 1].infos.data())}}
                        />
                        }
                      </Stack>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/** map */}
        {smUp && mdUp && (
          <Grid item xs={5}>
            <Box
              sx={{ bgcolor: "gainsboro", width: "100%", position: "fixed" }}
            >
              {
                //<MapsClustering centerC={center} />
              }
              <MapsSearch
                loading={isLoaded}
                centerC={center}
                eventsList={events}
                hover={hoverE}
              />
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default SearchPage;
