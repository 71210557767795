import Companies from "./Components/Companies";
import Guide from "./Components/Guide";
import Hero from "./Components/Hero";
import Properties from "./Components/Properties";
import Details from "./Components/Details";
import GetStarted from "./Components/GetStarted";
import Footer from "./Components/Footer";
import { AuthContextProvider } from "./context/AuthContext";
import Router from "./route";
import { VariableContextProvider } from "./context/VariableContext";

function App() {
  return (
    <>
      <AuthContextProvider>
        <VariableContextProvider>
          {/*<Hero />
        <Companies /> 
        <Guide />
        <Properties />
        <Details />
        <GetStarted />
        <Footer />*/}
          <Router />
        </VariableContextProvider>
      </AuthContextProvider>
    </>
  );
}

export default App;
