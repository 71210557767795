import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  Divider,
  Grid,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Modal,
  Stack,
} from "@mui/material";
import androidBadge from "../media/google-play-badge.png";
import iosBadge from "../media/ios.svg";
import CircularProgress from "@mui/material/CircularProgress";
import CustomButton from "../Components/CustomButton";
import logoImg from "../media/suspect.jpeg";
import Footer from "../Components/Footer";
import { UserAuth } from "../context/AuthContext";
import Iconify from "../Components/Iconify";
import { useNavigate, useParams } from "react-router-dom";
import Ticket from "../Components/profil/ticket";
import { collection, doc, getDoc, getDocs, query } from "firebase/firestore";
import { db, functions } from "../firebase.config";
import { httpsCallable } from "firebase/functions";
import PdfTickets from "../Components/pdf/pdfTickets";
import { PDFDownloadLink } from "@react-pdf/renderer";
var QRCode = require("qrcode");

// ----------------------------------------------------------------------

const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 400,
  bgcolor: "white",
  border: "1px solid white",
  borderRadius: "7px",
  boxShadow: 0,
  p: 3,
};

export default function Confirmation() {
  const { confirmID } = useParams();
  const [loading, setLoading] = React.useState();
  const [event, setEvent] = React.useState([]);
  const [eventID, setEventID] = React.useState([]);
  const [bill, setBill] = React.useState([]);
  const [qrcodes, setQrcodes] = React.useState([]);
  const [infos, setInfos] = React.useState([]);
  const [refCommande, setRefCommande] = React.useState();
  const navigate = useNavigate();

  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        //remove the paiement page

        // check
        const addMessage = httpsCallable(functions, "generateTicket");
        const result = await addMessage({ confirm: confirmID });
        console.log(result);
        // const q = query(collection(db, `evenement/${eventID}/tickets`));
        const docRef = doc(db, "confirmations", `${confirmID}`);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          console.log(docSnap.data());
          const refBillSnap = await getDoc(
            doc(db, "bills", docSnap.data().refBill)
          );
          if (refBillSnap.exists()) {
            console.log(refBillSnap.data());
            const eventSnap = await getDoc(
              doc(db, "evenement", refBillSnap.data().event)
            );
            console.log(refBillSnap.data());
            setEventID(eventSnap.id);
            setEvent(eventSnap.data());
            setBill(refBillSnap.data());
          }
        }

        // console.log(docSnap.data());
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    })();
  }, []);
 
   // Modal download liste participants
   const [openDownloadModal, setOpenDownloadModal] = React.useState(false);
   const handleOpenDownloadModal = () => setOpenDownloadModal(true);
   const handleCloseDownloadModal = () => setOpenDownloadModal(false);
 
   const downloadTicket = async () => {
     // generation des qr code
     try {
      // commande 
      // billet
      bill.billets.forEach(element => {
        /* const tmp = {
          billet: refBillebtOld.data(),
          event: event.data(),
          commande: element,
        };
        console.log("tmp : ", tmp);
        setInfo((infos) => [...infos, tmp]); */

        QRCode.toDataURL(
          element,
          {
            width: 130,
            margin: 1,
            errorCorrectionLevel: "H",
            color: {
              dark: "#000000",
              light: "#ffffff",
            },
          },
          (err, url) => {
            if (err) return console.log("erreur : ", err);
            setQrcodes((qrcodes) => [...qrcodes, url]);
            // setQr(url);
          }
        );
      });
      
    } catch (error) {
      console.log(error);
    }
     handleOpenDownloadModal();
     // handleCloseDownloadModal();
   };
  return (
    <Container sx={{ paddingX: 5, paddingY: 15, color: "white" }}>
      <Box
        sx={{
          background: "linear-gradient(#292929, #999999)",
          position: "fixed",
          width: "100%",
          height: "100vh",
          bottom: 0,
          left: 0,
          top: 0,
          zIndex: -1,
        }}
      />
      {loading ? (
        <Box
          sx={{ paddingY: 20 }}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Stack justifyContent="center" alignItems="center">
            <CircularProgress sx={{ color: "white", width: 70, height: 70 }} />
            <Typography>Generation des tickets...</Typography>
          </Stack>
        </Box>
      ) : (
        <>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Iconify
              icon="el:ok-sign"
              sx={{
                width: 70,
                height: 70,
                alignItems: "center",
                color: "green",
              }}
            />
          </Box>

          <Typography
            sx={{
              fontSize: "30px",
              fontWeight: "800",
            }}
            align="center"
          >
            Félicitation, Ton billet est callé !!!
          </Typography>
          <Box paddingY={2} display="flex" justifyContent="center">
            {loading ? (
              <CircularProgress />
            ) : (
              <Card
                elevation={10}
                sx={{
                  width: { xs: "100%", sm: "70%" },
                  borderRadius: { xs: "7px", sm: "7px" },
                }}
              >
                <CardMedia
                  component="img"
                  sx={{ width: "100%", objectFit: "cover" }}
                  height={{ xs: "375", sm: "425" }}
                  image={event["idVisuel"]}
                  title="green iguana"
                />
              </Card>
            )}
          </Box>

          <Box
            sx={{
              width: "100%",
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              sx={{ width: { xs: "100%", sm: "60%" }, paddingTop: 5 }}
              justifyContent="center"
            >
              <Stack
                paddingY={2}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Stack direction="row" spacing={1}>
                  <Iconify
                    icon="bi:ticket-perforated"
                    sx={{
                      width: 24,
                      height: 24,
                      alignItems: "center",
                    }}
                  />
                  <Typography fontSize={18}>
                    Télécharger votre billet
                  </Typography>
                </Stack>
                <Button
                  size="large"
                  sx={{
                    textTransform: "initial",
                    border: "2px solid #5E17EB",
                    backgroundColor: "#5E17EB",
                    color: "white",
                    borderRadius: "7px",
                    boxShadow: "none",
                    "&:hover": {
                      backgroundColor: "#5E17EB",
                      border: "2px solid #5E17EB",
                      color: "white",
                    },
                  }}
                  variant="contained"
                  // onClick={() => downloadTicket()}
                >
                  <Stack
                    spacing={1}
                    direction="row"
                    display="flex"
                    alignItems="center"
                  >
                    <Iconify
                      icon="material-symbols:download"
                      sx={{ height: 24, width: 24 }}
                    />
                    <Typography fontSize={16}>Télécharger</Typography>{" "}
                  </Stack>
                </Button>
                <Modal
            open={openDownloadModal}
            onClose={handleCloseDownloadModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ borderRadius: "7px" }}
          >
            <Box sx={styleModal}>
              <Typography variant="body2">
                Cliquez{" "}
                <PDFDownloadLink
                  document={<PdfTickets infos={infos} qrs={qrcodes} />}
                  fileName={`${
                    infos ? infos?.billet?.refCommande : "undefined"
                  }.pdf`}
                >
                  {({ blob, url, loading, error }) => {
                    console.log("erreur : ", error);
                    return loading ? "Genration du ticket......" : "ici";
                  }}
                </PDFDownloadLink>{" "}
                pour télécharger le ticket
              </Typography>
            </Box>
          </Modal>
              </Stack>
              <Divider />
              <Stack
                paddingY={2}
                direction="row"
                justifyContent="space-between"
              >
                <Stack direction="row" spacing={1}>
                  <Iconify
                    icon="bi:send"
                    sx={{
                      width: 24,
                      height: 24,
                      alignItems: "center",
                    }}
                  />
                  <Typography fontSize={18}>
                    Ton reçu ({bill?.totalPrix} CFA) et ton billet ont été
                    envoyés à <strong>{bill?.infoAcheteur?.email}</strong>
                  </Typography>
                </Stack>
              </Stack>
              <Divider />
              <Stack
                paddingY={2}
                direction="row"
                justifyContent="space-between"
              >
                <Stack direction="row" spacing={1}>
                  <Iconify
                    icon="solar:help-outline"
                    sx={{
                      width: 24,
                      height: 24,
                      alignItems: "center",
                    }}
                  />
                  <Typography fontSize={18}>
                    Besoin d’aide ? Contactez-nous ou visite notre FAQ
                  </Typography>
                </Stack>
              </Stack>
              <Divider />
              <Stack
                paddingY={2}
                direction="row"
                justifyContent="space-between"
              >
                <Stack direction="row" spacing={1}>
                  <Iconify
                    icon="ri:arrow-go-back-line"
                    sx={{
                      width: 24,
                      height: 24,
                      alignItems: "center",
                    }}
                  />
                  <Link
                    href={`http://localhost:3000/fr/event/${eventID}`}
                    underline="none"
                    color="white"
                  >
                    <Typography fontSize={18}>
                      Retour à la page de l'événement
                    </Typography>
                  </Link>
                </Stack>
              </Stack>
            </Box>
          </Box>

          <Card elevation={0} sx={{ bgcolor: "lightblue", marginY: 5 }}>
            <CardContent>
              <Typography fontSize={16}>
                La revente de billets est autorisée uniquement sur l'application
                Choptonticket avec son systeme de revente intégré et sécurisé.
              </Typography>
            </CardContent>
          </Card>
          <Stack direction="row" spacing={2} justifyContent="center">
            <img
              src={androidBadge}
              alt="illustration"
              style={{ maxWidth: 130 }}
            />
            <img style={{ Width: 150 }} src={iosBadge} alt="Logo" />
          </Stack>
        </>
      )}
    </Container>
  );
}
